import classNames from "classnames";
import { FiChevronDown } from "react-icons/fi";
import * as React from "react";
import { styles } from "components/search-results/filters/filters.styles";
import { WithStyles } from "@material-ui/styles";

interface Props extends WithStyles<typeof styles> {
  isOpen: boolean;
  title: string;
  toggleOpen: (isOpen: boolean) => void;
}

export class FilterSectionHeader extends React.Component<Props> {
  render() {
    const { classes, isOpen, title } = this.props;
    return (
      <div
        className={classNames(
          classes.sectionHeader,
          classes.row,
          classes.sectionArrow
        )}
        onClick={this.toggleOpen}
        tabIndex={0}
        role="button"
      >
        <span className={classes.sectionHeaderTitle}>{title}</span>
        <div className={classNames("text-right")}>
          <FiChevronDown
            size={"1.5em"}
            className={classNames({
              [classes.sectionArrowUp]: isOpen,
            })}
          />
        </div>
      </div>
    );
  }

  private toggleOpen = () => this.props.toggleOpen(!this.props.isOpen);
}
