import { createStyles } from "@material-ui/styles";
import { Theme } from "components/common/theme/default";

export const styles = (theme: Theme) =>
  createStyles({
    searchForm: {
      background: theme.color.background,
      color: theme.color.text,
      backgroundPosition: "center, right bottom",
      backgroundSize: "cover",
      paddingTop: "75px",
      paddingBottom: "20px",
      "& .text-center": {
        "& a": {
          color: "white !important",
          lineHeight: "24px",
          fontSize: "16px",
          fontWeight: 300,
          textDecoration: "none",
          borderBottom: "1px solid",
          whiteSpace: "nowrap",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
      "& h1": {
        margin: 0,
        padding: "60px 0",
        fontWeight: 300,
        whiteSpace: "nowrap",
        fontSize: "50px",
        lineHeight: "64px",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          fontSize: "32px",
          lineHeight: "40px",
          padding: "60px 0 30px 0",
        },
      },
      "& input": {
        borderColor: theme.color.inputBorder,
        padding: "8px 16px",
        color: theme.color.text,
        backgroundColor: "rgba(255,255,255, 0.2)",
        marginBottom: "30px",
        borderRadius: "4px",
        lineHeight: "24px",
        fontSize: "16px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "38px !important",
      },
      "& label": {
        fontWeight: 900,
      },
      "& input::placeholder": {
        color: theme.color.text,
      },
      "& :-ms-input-placeholder": {
        color: "white !important",
        fontSize: "14px",
      },
      "& input::-ms-clear": {
        display: "none",
      },
      "& button:hover": {
        backgroundColor: "#00ACD9",
        color: "rgba(255,255,255)",
      },
      "& button": {
        borderColor: theme.color.inputBorder,
        backgroundColor: "white",
        color: "#00ACD9",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: 0,
        padding: "8px 16px",
        lineHeight: "20px",
        marginTop: "32px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
          minWidth: "110px",
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          maxWidth: "auto",
          width: "100%",
          marginTop: "10px",
          marginBottom: "15px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "30px 0 60px 0",
      },
    },
    locationInput: {},
    socialShareContainer: {
      padding: "60px 15px 60px 0",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    socialShareContainerSm: {
      padding: "25px 0 0 0",
      display: "none",
      textAlign: "center",
      "& span": {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  });
