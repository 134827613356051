import { withStrings, WithStrings } from "components/locale";
import { defaultClassNames } from "components/common/props";
import { styles } from "src/components/search-results/resultsList/resultsList.style";
import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/styles";
import { Col, Container, Row } from "reactstrap";
import { GetStudyResults } from "components/fetch/stApi";
import Loading from "components/loading/loading";
import { FetchResponse } from "react-request";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Button from "components/common/button/button";
import { SearchModel } from "src/model/searchModel";
import { StApiResponse } from "components/fetch/models/common";
import {
  GetStudyResultsRequest,
  StudyResult,
} from "components/fetch/models/searchResults";
import { Link, withPrefix } from "gatsby";
import { getAgeLabel } from "src/utils/studyFormHelpers";
import FilterIcon from "static/images/Filter.svg";
import ClampLines from "react-clamp-lines";

interface Props extends WithStyles<typeof styles>, WithStrings {
  searchModel: SearchModel;
  classNames?: typeof defaultClassNames;
  onFilter: () => void;
}

interface State {
  searchModel: SearchModel;
  sortOrder: "asc" | "desc";
  sortField: string;
  pageIndex: number;
  pageSize: number;
  count: number;
  total: number;
}

class ResultsList extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, prevState: State): State {
    const searchModel = props.searchModel;
    const prevModel = prevState.searchModel;
    if (searchModel === prevModel) {
      return prevState;
    }
    return {
      ...prevState,
      searchModel,
      pageIndex: 0,
      count: 0,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      total: 0,
      count: 0,
      pageSize: 10,
      pageIndex: 0,
      sortOrder: "asc",
      sortField: "Location_Distance",
      searchModel: this.props.searchModel,
    };
  }

  render = () => {
    const { classes, onFilter, strings, format } = this.props;
    const { count, total } = this.state;
    return (
      <div className={classes.resultsContainer}>
        <div className={classes.header}>
          <div className={classes.filterBlock} onClick={onFilter}>
            <FilterIcon className={classes.filterIcon} />
            <b>{strings.searchResults.filtersHeader}</b>
          </div>
          <div className={classes.resultsTotal}>
            {format(strings.searchResults.results, { count, total })}
          </div>
        </div>
        <Container className={classes.resultsList}>
          <Row key={"resultElement-headers"} className={classes.resultsHeaders}>
            <Col>{strings.searchResults.condition}</Col>
            {this.getSortableHeaders()}
            <Col />
          </Row>
          {total === 0 && (
            <span className={classes.noResult}>{strings.common.noResults}</span>
          )}
          {this.getList()}
        </Container>
        <div className={classes.resultsListMore}>
          {count < total && (
            <a role="button" tabIndex={0} onClick={this.onLoadMore}>
              {strings.searchResults.loadMore}
            </a>
          )}
        </div>
      </div>
    );
  };

  private getSortableHeaders = () => {
    const { strings } = this.props;
    const { sortField } = this.state;
    return [
      { parameter: "StatusDisplay", title: strings.common.status },
      { parameter: "MinAge", title: strings.common.requirements },
      { parameter: "Location_Distance", title: strings.common.distance },
    ].map((el) => (
      <Col
        key={`header-${el.parameter}`}
        data-param={el.parameter}
        onClick={this.onSort}
      >
        <button >{el.title}</button>
        {el.parameter === sortField ? this.getDirectionArrow() : null}
      </Col>
    ));
  };

  private getList() {
    const { pageIndex } = this.state;
    const list = [];
    for (let index = 0; index <= pageIndex; index++) {
      const params = this.prepareRequest(index);
      const item = (
        <GetStudyResults
          key={`results-${index}`}
          request={params}
          afterFetch={this.onLoaded}
        >
          {({ data, error }) => {
            if (data && data.Data) {
              return this.getListItems(data.Data, index);
            }
            if (error) {
              return "Error fetching study results";
            }
            return <Loading color={{ fromTheme: "background" }} />;
          }}
        </GetStudyResults>
      );
      list.push(item);
    }
    return list;
  }

  private prepareRequest = (pageIndex: number) => {
    const {
      lat,
      lng,
      keywords,
      filters,
      conditions,
      distance,
    } = this.props.searchModel;
    const { pageSize, sortField, sortOrder } = this.state;
    const request: GetStudyResultsRequest = {
      MileRadius: parseInt(distance + "", 10) || 500,
      PageIndex: pageIndex,
      PageSize: pageSize,
      SortField: sortField,
      SortOrder: sortOrder,
      Latitude: lat,
      Longitude: lng,
      SearchTerm: keywords,
      IsQuerySearch: false,
    };
    if (filters) {
      for (const filter in filters) {
        if (!filters.hasOwnProperty(filter)) {
          continue;
        }
        const value = filters[filter];
        request[filter] = value && value.join("~");
      }
    }
    if (conditions) {
      request.Conditions = conditions.join("~");
    }
    return request;
  };

  private onLoaded = ({
    data,
    error,
  }: FetchResponse<StApiResponse<StudyResult[]>>) => {
    if (!data || !data.Data || error) {
      return;
    }
    const count = this.state.count;
    this.setState({
      total: data.Count,
      count: count + data.Data.length,
    });
  };

  private onLoadMore = () => {
    const { pageIndex } = this.state;
    this.setState({
      pageIndex: pageIndex + 1,
    });
  };

  private onSort = (e: any) => {
    const sortField = e.currentTarget.getAttribute("data-param");
    this.setState({
      sortOrder: this.getDirection(sortField),
      pageIndex: 0,
      sortField,
      count: 0,
    });
  };

  private getDirection = (newSortField: string) => {
    const { sortOrder, sortField } = this.state;
    if (sortField !== newSortField) {
      return "asc";
    }
    return sortOrder === "asc" ? "desc" : "asc";
  };

  private getDirectionArrow = () => {
    if (this.state.sortOrder === "asc") {
      return <IoIosArrowDown />;
    }
    if (this.state.sortOrder === "desc") {
      return <IoIosArrowUp />;
    }
    return null;
  };

  private getListItems = (results: StudyResult[], parentIndex: number) => {
    const { classes, strings, format } = this.props;
    return results.map((result, index) => {
      const condition =
        result.Conditions &&
        result.Conditions.map((con: any) => con.LookupValue)
          .toString()
          .split(",")
          .filter((val, id, array) => array.indexOf(val) == id)
          .filter((value) => value && value !== "Unmapped")
          .filter((value) => value && value !== "COVID-19")
          .filter((value) => value && value !== "COVID19")
          .join(", ");

      const distance =
        result.Location_Distance !== null ? (
          format(strings.common.distanceValue, {
            distance: result.Location_Distance.toFixed(2),
          })
        ) : (
          <p className={"loc-info"}>
            {"To find a center near you, please enter your location"}
          </p>
        );

      return (
        <Row
          key={`resultElement-${parentIndex}-${index}`}
          className={classes.resultsListItem}
        >
          <Col>
            <div className={classes.resultItemSmallTitle}>
              {strings.searchResults.condition}
            </div>
            <span className={classes.resultItemContent}>
              {"COVID-19" + `${condition && ", " + condition}`}
            </span>
          </Col>
          <Col>
            <div className={classes.resultItemSmallTitle}>
              {strings.common.status}
            </div>
            <span className={classes.resultItemContent}>
              <i
                className={classes.smallIcon}
                data-icon={result.StatusInternal}
              />
              <span>{result.StatusDisplay}</span>
            </span>
          </Col>
          <Col>
            <div className={classes.resultItemSmallTitle}>
              {strings.common.requirements}
            </div>
            <span className={classes.resultItemContent}>
              {this.getGenderIcon(result.GenderInternal)}
              {format(strings.common.yearsValue, {
                years: getAgeLabel(result.MinAge, result.MaxAge),
              })}
            </span>
          </Col>
          <Col>
            <div className={classes.resultItemSmallTitle}>
              {strings.common.distance}
            </div>
            <span className={classes.resultItemContent}>{distance}</span>
          </Col>
          <Col>
            <div className={classes.resultItemSmallTitle} />
            <Link
              className={classes.resultItemContent}
              to={withPrefix(
                `/details/?id=${result.UniqueIdentifier}` +
                  (this.state.searchModel.lat && this.state.searchModel.lng
                    ? `&searchLat=${result.Location_Latitude}&searchLong=${result.Location_Longitude}`
                    : "") +
                  (this.props.searchModel.distance
                    ? `&searchRadius=${this.props.searchModel.distance}&baseLat=${this.props.searchModel.lat}&baseLong=${this.props.searchModel.lng}`
                    : "") +
                  `&condition=${condition}`+
                  `&title=${result.Title}`
              )}
            >
              <Button className={classes.learnMoreButton}>
                {strings.searchResults.learnMore}
              </Button>
            </Link>
          </Col>
          <Col xs={12} className={"last-line"}>
            <ClampLines
              key={result.Id}
              text={`${result.UniqueIdentifier} - ${result.Title}`}
              id="text"
              lines={1}
              innerElement="div"
              ellipsis="..."
              moreText={"˅"}
              lessText={"˄"}
              className={classes.titleAndId}
            />
          </Col>
        </Row>
      );
    });
  };

  private getGenderIcon = (code: string) => {
    switch (code) {
      case "GENDER_FEMALE":
        return <img src={"/images/female.svg"} alt={"female"} />;
      case "GENDER_MALE":
        return <img src={"/images/male.svg"} alt={"male"} />;
      default:
        return <img src={"/images/female_male.svg"} alt={"Unknown"} />;
    }
  };
}

export default withStyles(styles)(withStrings(ResultsList));
