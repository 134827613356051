import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => {
    return createStyles({
        '@global': {
            'html, body': {
                fontFamily: theme.fontFamily,
                background: theme.color.backdropBackground,
                height: '100%',
                width: '100%',
                padding: 0,
                margin: 0,
            },
            h1: {
                lineHeight: '68px',
                fontSize: '50px',
                height: '68px',
                fontWeight: 400,
            },
            iframe: {
                '&#orbita-chat-iframe': {
                    right: '0 !important',
                    left: 'unset !important',
                    width: '82px !important',
                    height: '72px !important',
                },
            },
            div:{
                '&.ie11-chatbot':{
                    position:'fixed',
                    right:10,
                    bottom:0,
                    width: '82px',
                    height: '72px',
                    backgroundColor:'#fff',
                    borderRadius: '50%',
                    boxShadow: '0 6px 6px rgba(0,0,0,.25)',
                    display: 'none',
                    justifyContent: 'center',
                    marginBottom:10,
                    '& a':{
                        textDecoration:'none',
                        width:'80%',
                        '& img':{
                            width:'100%',
                        }
                    },
                    '@media all and (-ms-high-contrast: none)': {
                        display: 'flex',
                    }

                },
                '&#chatWindow': {
                    '&.openorbita iframe#orbita-chat-iframe' :{
                        width: '520px !important',
                        height: '640px!important',                        
                    },
                    '@media all and (-ms-high-contrast: none)': {
                        display: 'none',
                    }
                },
            },
            '@media all and (-ms-high-contrast: none)': {
                div:{
                    '&#chatWindow': {
                            display: 'none',
                    },
                },
            },
        },
        wrap: {
            maxWidth: '1440px',
            overflow: 'hidden',
            margin: '0 auto',
            height: '100%',
        },
    });
};
