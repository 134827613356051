import { SearchModel } from 'src/model/searchModel';
import { defaultSectionNames } from 'components/filter/filtersMapper';

export function serializeToQuery(model: SearchModel): string {
    const args: string[] = [];
    for (const key of Object.keys(model).sort()) {
        if (model[key] === undefined) {
            continue;
        }
        if (key === 'filters') {
            for (const [filterKey, filterItems] of Object.entries(
                model['filters']
            )) {
                args.push(`${filterKey}=${arrSerialize(filterItems)}`);
            }
        } else {
            args.push(`${key}=${serializeValue(model[key])}`);
        }
    }

    return args.length ? args.join('&') : '';
}

export function parseFromQuery(query: string): SearchModel {
    const model: SearchModel = {
        filters: {},
        keywords: ''
    };
    if (!query) {
        return model;
    }
    query = query[0] === '?' ? query.substr(1) : query;
    for (const statement of query.split('&')) {
        const [param, value] = statement.split('=');
        const section = defaultSectionNames.find(s => s.paramName === param);
        if (section) {
            model.filters[param] = deserializeValue(value);
        } else {
            model[param] = deserializeValue(value);
        }
    }
    return model;
}

function deserializeValue(value: string): any {
    if (!value) {
        return '';
    }
    if (value.indexOf(',') >= 0) {
        const arr = value.split(',').map(decodeURIComponent);
        if (value[value.length - 1] === ',') {
            return [arr[0]];
        }
        return arr;
    }
    return decodeURIComponent(value);
}

function serializeValue(value: any) {
    if (value === undefined) return '';
    if (value instanceof Array) return arrSerialize(value);
    if (typeof value === 'string') {
        return encodeURIComponent(value);
    }
    return value;
}

function arrSerialize(arr?: any[]) {
    if (!arr || !arr.length) return '';
    if (arr.length === 1) {
        return arr[0] + ',';
    }
    return arr.join();
}
