import * as React from 'react';
import { TenantDataResolver } from 'src/services/tenantDataResolver';
import { TenantModel, TemplateContext, TemplateSource } from 'src/model';
import * as ejs from 'ejs';
import { Async } from 'components/async/async';
import { ReactNode } from 'react';
import parseHtml from 'html-react-parser';
import { LocaleValue, LocaleContext } from 'components/locale';
import Loading from 'components/loading/loading';
import { Theme } from 'components/common/theme/default';
import { withTheme } from '@material-ui/styles';
import axios from 'axios';
import { navigate } from '@reach/router';

interface Props {
    resolver: TenantDataResolver;
    templateSrc: TemplateSource;
    tenant: TenantModel;
    theme: Theme;
}

interface DeferredRawData {
    href: string;
    id: string;
}

class EjsTemplateComponent extends React.Component<Props> {
    private prev: ReactNode | null;

    private deferredData: DeferredRawData[] = [];

    render = () => {
        const { resolver, templateSrc } = this.props;
        const templatePromise = resolver.resolveTemplateSource(templateSrc);

        return (
            <LocaleContext.Consumer>
                {locale => (
                    <Async
                        promise={templatePromise.then(template => this.renderTemplate(template, locale))}
                        onDidUpdate={locale.updateDom}
                    >
                        {({ data, error, isLoading }) => {
                            if (isLoading) {
                                return this.prev || <Loading />;
                            }
                            if (error) {
                                console.error('error rendering template', error);
                                return null;
                            }
                            if (this.prev !== data) {
                                setTimeout(this.onAfterRender, 0);
                            }
                            return (this.prev = data);
                        }}
                    </Async>)}
            </LocaleContext.Consumer>
        );
    };

    onAfterRender = () => {
        for (const {href, id} of this.deferredData) {
            const el = document.getElementById(id);
            if (!el) {
                console.error('Cannot find', {href, id});
                continue;
            }
            axios.get(href)
                .then(rsp => el.outerHTML = rsp.data)
                .catch(e => {
                    console.error('Cannot find', {href, id}, e);
                    el.parentNode!.removeChild(el);
                });
        }
        document.querySelectorAll('.rootLink').forEach((link: HTMLElement) => {
            link.onclick = ev => {
                ev.preventDefault();
                navigate('/');
            };
        });
        this.deferredData = [];
    }

    private renderTemplate = (template: string, locale: LocaleValue) =>
        parseHtml(ejs.render(template, this.getContext(locale)));

    private getContext = (locale: LocaleValue): TemplateContext => {
        const { tenant, theme } = this.props;
        return {
            tenant,
            theme,
            locale: locale.locale,
            strings: locale.strings,
            allLocales: locale.allLocales,
            social: tenant.social || {},
            footerLinks: tenant.footerLinks || {},
            localize: locale.localize,
            renderRaw: this.renderRaw
        };
    };

    private renderRaw = (href: string) => {
        const id = `dyn-${Math.random()}`;
        this.deferredData.push({href, id});
        return `<div id="${id}"></div>`;
    }
}

export default withTheme(EjsTemplateComponent);
