//@ts-ignore

export const getFirstSugestion = () => {
  const suggestionsContainer = document.getElementsByClassName(
    "pac-container"
  )[0];
  let text = "";
  let location = {
    lat: "",
    lng: "",
    locationName: "",
  };
  return new Promise((resolve, reject) => {
    if (suggestionsContainer.children.length > 0) {
      const firstSugestion = document.getElementsByClassName("pac-item")[0];
      if (firstSugestion.childNodes !== undefined) {
        text = firstSugestion.childNodes[1].textContent
          ? firstSugestion.childNodes[1].textContent
          : "";
        if (firstSugestion.childNodes[2].textContent !== "") {
          text = text + ", " + firstSugestion.childNodes[2].textContent;
        }
        const placesService = new google.maps.places.PlacesService(
          document.createElement("div")
        );
        const request = {
          query: text,
          fields: ["geometry", "formatted_address", "place_id"],
        };
        placesService.findPlaceFromQuery(request, (result, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            if (result[0] && result[0].geometry) {
              location = {
                lat: result[0].geometry.location.lat().toString(),
                lng: result[0].geometry.location.lng().toString(),
                locationName: text,
              };
              resolve(location);
            }
          }
        });
      }
    }
  });
};
