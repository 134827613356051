import { FiltersSection, FilterItem } from 'components/filter/types';
import { StudyCodesMap } from 'components/fetch/models/studyCodes';
import { common } from 'components/locale/strings/en';
import { LocaleStrings } from 'components/locale/strings';

export const mapFilters = ({
    data, strings
}: {
    data: StudyCodesMap;
    strings: LocaleStrings['common']
}): FiltersSection[] => {
    return defaultSectionNames
        .filter(el => data[el.sectionKey])
        .map(el => mapSection(data, strings, el));
};

const mapSectionItemChildren = (item: any): FilterItem[] | null => {
    const children: [] = item.Children;
    if (!children) {
        return null;
    }
    return children.map((i: any) => ({
        displayValue: i.DisplayValue,
        internalValue: i.InternalValue,
        sortOrder: i.SortOrder,
        items: [],
    }));
};

const filterSort = (x: FilterItem, y: FilterItem) =>
    x.sortOrder > y.sortOrder ? 1 : -1;

export const mapSection = (
    data: StudyCodesMap,
    strings: LocaleStrings['common'],
    { sectionKey, name, paramName }: any
): FiltersSection => {
    const dataSection = data[sectionKey];
    if (!dataSection) {
        console.log(`Section ${sectionKey} not found`);
    }
    const mappedItems: FilterItem[] = dataSection.map((i: any) => {
        const children = mapSectionItemChildren(i);
        if (children) {
            children.sort(filterSort);
        }
        return {
            displayValue: i.DisplayValue,
            internalValue: i.InternalValue,
            sortOrder: i.SortOrder,
            items: children || [],
        };
    });
    mappedItems.sort(filterSort);
    return {
        parameterName: paramName,
        sectionName: strings[name],
        sectionKey,
        items: mappedItems,
    };
};

interface SectionNameData {
    sectionKey: string;
    name: keyof typeof common;
    paramName: string;
}

export const defaultSectionNames: SectionNameData[] = [
    {
        sectionKey: 'RECSTATUS',
        name: 'studyStatus',
        paramName: 'Status',
    },
    { sectionKey: 'PHASE', name: 'phase', paramName: 'Phases' },
    { sectionKey: 'AGERANGE', name: 'age', paramName: 'AgeRanges' },
    { sectionKey: 'GENDER', name: 'sex', paramName: 'Gender' },
];
