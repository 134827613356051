import { Fetch } from 'react-request';
import * as React from 'react';
import { TenantModel } from 'src/model';
import { FetchRq } from 'components/fetch/models/common';
import { GetStudyCodesRq } from 'components/fetch/models/studyCodes';
import { Location } from '@reach/router';

type TenantRq = FetchRq<TenantModel, { host: string }>;
export const FetchTenant = ({ host, children }: TenantRq) => (
    <Fetch
        url={`${process.env.GATSBY_STORAGE_URL}tenants/${host}/index.json`}
        children={children}
    />
);

export const GetStudyCodes = (props: GetStudyCodesRq) => {
    return <Location>
        {({ location }) => (
            <Fetch
                {...{
                    url: `${process.env.GATSBY_STORAGE_URL}tenants/${location.host}/master-data.json`,
                    ...props,
                }}
            />
        )}
    </Location>;
};
