import { createStyles } from '@material-ui/styles';

export const styles = createStyles({
    overlay: {
        position: 'fixed',
        minWidth: '100%',
        minHeight: '100%',
        left: 0,
        right: 0,
        top: 0,
        background: 'rgba(0,0,0,0.5)',
        zIndex: 900,
    },
});
