import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    resultsContainer: {
        backgroundColor: theme.color.filtersBackground,
    },
    results: {
        [theme.breakpoints.down('lg')]: {
            padding: '0',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 'initial',
            padding: 0,
        },
    },
});
