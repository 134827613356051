import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    modalContainer: {
        margin: 0,
        width: '100vw',
        height: '100vh',
        display: 'none',
        padding: 0,
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    modalContent: {
        height: 'auto',
        minHeight: '100%',
        width: '100vw',
        borderRadius: 0,
        padding: '7px 27px 0 27px',
    },
    modalBody: {
        padding: 0,
    },
    modalDialog: {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
    },
    close: {
        right: 27,
        top: 21
    },
    filtersContainer: {
        padding: 0,
    },
});
