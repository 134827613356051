import { createStyles } from "@material-ui/styles";
import { Theme } from "components/common/theme/default";
import { iconsStyles } from "components/common/theme/styles";

export const styles = (theme: Theme) =>
  createStyles({
    ...iconsStyles,
    resultsContainer: {
      background: theme.color.filtersBackground,
      padding: "3px 0 0 0",
    },
    header: {
      padding: "30px 0",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        borderBottom: `1px solid ${theme.color.separator}`,
        padding: "20px 30px 20px 30px",
      },
    },
    resultsTotal: {
      lineHeight: "29px",
      fontSize: "21px",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        margin: "0 0 0 auto",
        maxWidth: "540px",
        fontSize: "14px",
      },
    },
    filterBlock: {
      cursor: "pointer",
      textAlign: "left",
      display: "none",
      margin: "auto",
      "& b": {
        verticalAlign: "middle",
        paddingLeft: "0.5em",
        lineHeight: "29px",
      },
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
        fontSize: "14px",
        margin: 0,
      },
    },
    resultsHeaders: {
      border: `1px solid ${theme.color.separator}`,
      alignItems: "center",
      borderRight: "none",
      borderLeft: "none",
      fontSize: "16px",
      fontWeight: 600,
      display: "flex",
      height: "62px",
      "& span": {
        cursor: "pointer",
      },
      "& button": {
        border: "0",
        backgroundColor: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        color: "#212529",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    resultsList: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "initial",
        padding: 0,
      },
    },
    resultsListItem: {
      "& .col": {
        justifyContent: "left",
        alignItems: "center",
        overflow: "hidden",
        minHeight: "122px",
        display: "flex",
        fontSize: "14px",
        "& span": {
          display: "inline-block",
        },
        "& i": {
          verticalAlign: "middle",
        },
        "& img": {
          marginRight: "5px",
          userSelect: "none",
          height: "25px",
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: 0,
          fontSize: "14px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
      "& .col:nth-child(3)": {
        whiteSpace: "nowrap",
      },
      "&:nth-child(odd)": {
        background: theme.color.advancedBackground,
      },
      "&:nth-child(even)": {
        background: theme.color.filtersBackground,
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        padding: "30px",
      },
      "& .last-line": {
        [theme.breakpoints.down("md")]: {
          paddingTop: "15px",
        },
      },
    },
    resultItemSmallTitle: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 0 10px 0",
        display: "inline-block",
        fontWeight: 600,
        width: "50%",
      },
    },
    noResult: {
      paddingTop: "15px",
      display: "block",
    },
    resultItemContent: {
      "& .loc-info": {
        lineHeight: "19px",
        fontSize: "14px",
        margin: "8px 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px 0 10px 0",
        display: "inline-block",
        width: "50%",
        "& .loc-info": {
          fontSize: "14px",
        },
      },
    },
    resultsListMore: {
      padding: "35px 0 60px 0",
      textAlign: "center",
      lineHeight: "22px",
      fontSize: "16px",
      height: "22px",
      "& a": {
        cursor: "pointer",
        color: "#00ACD9",
      },
    },
    titleAndId: {
      padding: "15px 17px 15px 0",
      fontSize: "14px",
      lineHeight: "16px",
      borderTop: " 1px solid rgba(151,151,151,0.5)",
      "& .clamp-lines__button": {
        background: "transparent",
        border: "2px solid #00ACD9",
        color: "#00ACD9",
        position: "absolute",
        padding: "0",
        width: "20px",
        height: "20px",
        borderRadius: "4px",
        right: "15px",
        top: "15px",
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
          top: "30px",
        },
        "&:focus": {
          outline: "none",
        },
      },
    },
    learnMoreButton: {
      padding: "0.5em 0.6em",
      border: "2px solid #00ACD9",
      color: "#00ACD9",
      backgroundColor: "transparent",
      minWidth: "110px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0.6em 0.6em",
        width: "130px",
      },
      "&:hover": {
        color: "rgba(255,255,255)",
        backgroundColor: "#00ACD9",
        border: "2px solid #00ACD9",
      },
    },
    filterIcon: {
      color: theme.color.searchButton,
    },
  });
