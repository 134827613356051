import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from './locationInput.styles';
import React from 'react';
import { Input } from 'reactstrap';

export interface Location {
    lat: number | string;
    lng: number | string;
    locationName: string;
}

interface Props extends WithStyles<typeof styles> {
    placeholder: string;
    value?: string;
    onLocationChanged: (location: Location) => void;
}

interface State {
    locationName?: string;
}

class LocationInput extends React.Component<Props, State> {
    private googleListener: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            locationName: props.value
        };
    }

    componentDidMount = () => {
        //TODO: replace with @google/maps lib
        if ((window as any).google) {
            this.setGoogleAutocomplete();
            return;
        }
        const script = document.createElement('script');
        script.src =
            'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAFq1fItucvNN8W751EX2dHcankbZcdFdA';
        script.onload = this.setGoogleAutocomplete;
        script.async = true;
        document.body.appendChild(script);
    };

    componentWillUnmount = () => {
        const google = (window as any).google;
        if (google && this.googleListener) {
            google.maps.event.removeListener(this.googleListener);
            document
                .querySelectorAll('.pac-container')
                .forEach(item =>
                    item.parentNode ? item.parentNode.removeChild(item) : null
                );
            this.googleListener = null;
        }
        document.removeEventListener('click', this.clickOutisde);
    };

    render = () => {
        const { placeholder, classes } = this.props;
        return (
            <div>
                <Input
                    id='location'
                    placeholder={placeholder}
                    className={classes.locationInput}
                    value={this.props.value}
                    onChange={this.onChange}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                            this.selectFirst();
                        }
                    }}
                    maxLength={250}
                />
            </div>
        );
    };

    private clickOutisde = (e: any) => {
        const suggestionsContainer = document.querySelector('.pac-cotainer');
        const suggestionsInput = document.querySelector('#location');
        let targetElement = e.target;
        if (
            targetElement === suggestionsContainer ||
            targetElement === suggestionsInput
        ) {
            return;
        }
       this.selectFirst();
    };

    private onChange = (e: any) => {
        this.setState({
            locationName: e.target.value
        });
        this.props.onLocationChanged({
            lat: '',
            lng: '',
            locationName: e.target.value
        });
    };

    private setGoogleAutocomplete = () => {
        const { classes, onLocationChanged } = this.props;
        const input = document.querySelector<HTMLInputElement>(
            `.${classes.locationInput}`
        );
        if (!input) {
            console.log("Location input can't be found");
            return;
        }
        const google = (window as any).google;
        const autocomplete = new google.maps.places.Autocomplete(input);
        this.googleListener = google.maps.event.addListener(
            autocomplete,
            'place_changed',
            () => {
                const place = autocomplete.getPlace();
                const location = place.geometry
                    ? {
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                          locationName: input.value
                      }
                    : { lat: '', lng: '', locationName: '' };
                this.setState({
                    locationName: location.locationName
                });
                onLocationChanged(location);
            }
        );
        document.addEventListener('click', this.clickOutisde);
    };

    private selectFirst = () => {
        const suggestionsContainer = document.getElementsByClassName(
            'pac-container'
        )[0];
        if (suggestionsContainer.children.length > 0 && this.props.value) {
            const firstSugestion = document.getElementsByClassName(
                'pac-item'
            )[0];
            if (firstSugestion.childNodes !== undefined) {
                let text = firstSugestion.childNodes[1].textContent
                    ? firstSugestion.childNodes[1].textContent
                    : '';
                if (firstSugestion.childNodes[2].textContent !== '') {
                    text =
                        text + ', ' + firstSugestion.childNodes[2].textContent;
                }
                this.setState({
                    locationName: text
                });
                this.props.onLocationChanged({
                    lat: '',
                    lng: '',
                    locationName: text
                });
            }
        }
    };
}

export default withStyles(styles)(LocationInput);
