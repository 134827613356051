import * as React from 'react';
import { TenantCssModel, TenantModel } from 'src/model';
import { ThemeProvider } from '@material-ui/styles';
import { defaultTheme, Theme } from 'components/common/theme/default';
import Helmet from 'react-helmet';
import * as merge from 'deepmerge';

interface Props {
    tenant: TenantModel;
    tenantCss?: keyof TenantCssModel;
    children: React.ReactNode;
}

class LoadTheme extends React.Component<Props> {

    render() {
        return <ThemeProvider theme={defaultTheme}>
            {this.renderHelmet()}
            {this.renderNestedTheme()}
        </ThemeProvider>;
    }

    componentDidMount(): void {
        const { tenant } = this.props;
        this.addCss(tenant.commonCssUrl, 'common-styles');
        this.renderFavicon(tenant.favicon);
    }

    renderHelmet() {
        const { tenant, tenantCss } = this.props;

        return <Helmet>
            {tenantCss && tenant[tenantCss]
                ? this.renderCss(tenant[tenantCss]!)
                : null}
        </Helmet>;
    }

    addCss = (href: string | undefined, id: string) => {
        if (href && !document.head.querySelector(`#${id}`)) {
            const link = document.createElement('link');
            link.id = id;
            link.href = href;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            document.head.appendChild(link);
        }
    };

    renderCss = (href: string) => <link rel='stylesheet' type='text/css' href={href} />;

    renderFavicon = (href: string | undefined) => {
        if (!href) {
            return;
        }
        const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = href;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    renderNestedTheme = () => {
        const { tenant, children } = this.props;
        if (!tenant.theme) {
            return children;
        }

        return <ThemeProvider theme={(theme: Theme) => (merge(theme, tenant.theme!))}>
            {children}
        </ThemeProvider>;
    };
}

export default LoadTheme;
