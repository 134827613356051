import * as React from "react";
import { ReactNode } from "react";
import { withStyles, WithStyles } from "@material-ui/styles";
import { styles } from "components/search-results/socialShare/socialShare.styles";
import { Row } from "reactstrap";

import Print from "static/images/social-icons_print.svg";
import Email from "static/images/social-icons_email.svg";
import Twitter from "static/images/social-icons_twitter.svg";
import Facebook from "static/images/social-icons_facebook.svg";
import Linkedin from "static/images/social-icons_linkedin.svg";
import { WithStrings, withStrings } from "components/locale";
import classNames from "classnames";

interface ShareTarget {
  icon: ReactNode;
  title: string;
  id: string;
  activate: () => void;
}

interface Props extends WithStyles<typeof styles>, WithStrings {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
}

class SocialShare extends React.Component<Props> {
  targets: ShareTarget[];

  constructor(props: Readonly<Props>) {
    super(props);
    this.targets = this.getTargets();
  }

  render = () => {
    const { classes, containerClassName, titleClassName, title } = this.props;
    return (
      <>
        <div className={classNames(classes.container, containerClassName)}>
          <span className={classNames(classes.shareText, titleClassName)}>
            {title}:
          </span>
          <div className={classes.buttonsContainer}>
            {this.targets.map(this.renderButton)}
          </div>
        </div>
      </>
    );
  };

  private renderButton = (target: ShareTarget) => {
    const { classes } = this.props;
    return (
      <div
        className={classes.button}
        key={target.id}
        title={target.title}
        onClick={target.activate}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            target.activate();
          }
        }}
        role={"button"}
        tabIndex={0}
      >
        {target.icon}
      </div>
    );
  };

  private getTargets() {
    const { strings } = this.props;
    const url = encodeURIComponent(document.location.href);
    const title = "Clinical Trials";
    return [
      {
        id: "twitter",
        title: "Twitter",
        icon: <Twitter />,
        activate: () => {
          window.open(`https://twitter.com/intent/tweet?text=${url}`);
        },
      },
      {
        id: "linkedin",
        title: "LinkedIn",
        icon: <Linkedin />,
        activate: () => {
          window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
          );
        },
      },
      {
        id: "facebook",
        title: "Facebook",
        icon: <Facebook />,
        activate: () => {
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
        },
      },
      {
        id: "email",
        title: strings.common.email,
        icon: <Email />,
        activate: () => {
          window.location.href = `mailto:?subject=${title}&body=${url}`;
        },
      },
      {
        id: "print",
        title: strings.common.print,
        icon: <Print />,
        activate: () => window.print(),
      },
    ];
  }
}

export default withStyles(styles)(withStrings(SocialShare));
