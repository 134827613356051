import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'components/common/checkbox/checkbox.styles';
import { ChangeEvent } from 'react';

export interface Props extends WithStyles<typeof styles> {
    item: {
        internalValue: string;
        displayValue: string;
    };
    inlineInfo?: React.ReactNode;
    isChecked?: boolean;
    parameterName?: string;
    onChanged: (value: boolean, props: Props) => void;
}

interface State {
    isChecked: boolean;
}

class Checkbox extends React.Component<Props, State> {
    static getDerivedStateFromProps(props: Props): State {
        return {
            isChecked: props.isChecked || false,
        };
    }

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = Checkbox.getDerivedStateFromProps(props);
    }

    render() {
        const { classes, item, parameterName, inlineInfo } = this.props;
        const { isChecked } = this.state;

        return (
            <div className={classes.container}>
                <label className={classes.checkbox}>
                    <input
                        type={'checkbox'}
                        checked={isChecked}
                        name={item.internalValue}
                        data-display={item.displayValue}
                        data-section={parameterName}
                        onChange={this.onChange}
                        id={`${item.internalValue}`}
                    />
                    <span>&#8203;</span>
                </label>
                <label
                    className={classes.label}
                    htmlFor={`${item.internalValue}`}
                >
                    {item.displayValue}
                    {inlineInfo || null}
                </label>
            </div>
        );
    }

    onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const { onChanged } = this.props;
        onChanged(evt.currentTarget.checked, this.props);
    };
}

export default withStyles(styles)(Checkbox);
