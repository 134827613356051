import { TemplateSource } from 'src/model';
import { resolveLocaleSource } from 'src/services/localeResolver';

export class TenantDataResolver {
    resolveLocaleSource = resolveLocaleSource;

    private cache: Map<string, any> = new Map<string, any>();

    async resolveTemplateSource(source?: TemplateSource): Promise<string> {
        if (!source) {
            return 'Error on resolving template';
        }
        const cacheResult = this.pullFromCache<string>(source);
        if (cacheResult.hasData) {
            return cacheResult.data;
        }
        if (typeof source === 'string') {
            return source;
        }
        if ('url' in source) {
            return fetch(source.url).then(async rsp => {
                if (rsp.ok) {
                    return this.addToCache(source.url, await rsp.text());
                } else {
                    return 'Error on fetching template';
                }
            });
        }
        return 'Error on resolving template';
    }

    private pullFromCache<T>(
        source: any
    ): { hasData: false } | { hasData: true; data: T } {
        if (source && source.url && this.cache.has(source.url)) {
            return { hasData: true, data: this.cache.get(source.url) };
        }
        return { hasData: false };
    }

    private addToCache<T>(key: string, value: T): T {
        this.cache.set(key, value);
        return value;
    }
}
