import * as React from 'react';
import Layout from 'components/layout/layout';
import Container from 'components/search-results/container/container';

export default ({ location }: { location: Location }) => {
    return (
        <Layout location={location} title={'Coronavirus - Clinical Trials Results'} description={'Clinical trials for: COVID-19'} tenantCss={'resultsCssUrl'}>
            <Container location={location} />
        </Layout>
    );
};
