import { createStyles } from "@material-ui/styles";
import { filterStyles as getFilterStyles } from "components/common/theme/styles";
import { Theme } from "components/common/theme/default";
import { inherits } from "util";

export const styles = (theme: Theme) => {
  const filterStyles = getFilterStyles(theme);
  return createStyles({
    componentHeader: {
      ...filterStyles.sectionName,
      [theme.breakpoints.down("md")]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0",
      },
    },
    row: {
      borderBottom: `1px solid ${theme.color.borderColor}`,
      minHeight: "50px",
    },
    filtersContainer: {
      padding: "35px 0",
      "& a": {
        cursor: "pointer",
      },
      "& button": {
        cursor: "pointer",
        color: "inherit",
      },
    },
    link: {
      ...filterStyles.link,
      border: "0px",
      backgroundColor: "transparent",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    medConditionsNestedContainer: {
      marginLeft: "25px",
      marginBottom: "15px",
    },
    checkboxContainer: {
      padding: "25px",
      maxHeight: "400px",
      overflow: "auto",
      transition: "all 0.2s",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "fit-content",
      },
    },
    mdContainer: {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    componentHeaderRow: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: "58px",
    },
    sectionHeader: {
      padding: "0 25px 0 25px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "60px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    sectionHeaderTitle: {
      ...filterStyles.sectionName,
      fontSize: "16px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "space-between",
    },
    sectionArrow: {
      cursor: "pointer",
    },
    sectionArrowUp: {
      transform: "rotate(180deg)",
    },
  });
};
