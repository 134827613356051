import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';
import { createDataAttributes } from 'components/common/theme/createDataAttributes';

export const filterStyles = (theme: Theme) => createStyles({
    sectionName: {
        color: theme.color.sectionName,
        lineHeight: '25px',
        fontSize: '18px',
        fontWeight: 600,
    },
    link: {
        color: theme.color.inputText,
        lineHeight: '22px',
        fontSize: '16px',
        height: '22px',
        '&:hover': {
            textDecoration: 'none',
            color: theme.color.inputText,
        },
    },
});

export const commonStyles = (theme: Theme) => ({
    headerTitle: {
        fontWeight: 500,
        color: theme.color.title,
        fontSize: '30px',
    },
    headerTitleLight: {
        fontWeight: 'normal',
        color: theme.color.title,
        fontSize: '26px',
        lineHeight: '36px',
    },
});

const defaultIconStyle = {
    backgroundImage: 'url(/images/other.png)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'inline-block',
    marginRight: '5px',
};

const completed = [
    'COMPLETE',
    'COMPLETED',
    'STUDY COMPLETED',
    'RECSTATUS_STUDY_COMPLETED',
    'RECSTATUS_COMPLETED',
    'STUDY COMPLETE',
];
const recruiting = [
    'RECRUITING',
    'AVAILABLE',
    'RECSTATUS_RECRUITING',
    'RECSTATUS_AVAILABLE',
];
const notRecruiting = [
    'NOT RECRUITING',
    'ACTIVE NOT RECRUITING',
    'NOT YET RECRUITING',
    'RECSTATUS_ACTIVE_NOT_RECRUITING',
    'RECSTATUS_NOT_YET_RECRUITING',
    'WILL BE RECRUITING',
];
const terminated = [
    'TERMINATED',
    'WITHDRAWN',
    'RECSTATUS_TERMINATED',
    'RECSTATUS_WITHDRAWN',
];

export const iconsStyles = createStyles({
    '@global': {
        [createDataAttributes('icon', completed)]: {
            backgroundImage: 'url(/images/completed.svg) !important',
        },
        [createDataAttributes('icon', recruiting)]: {
            backgroundImage: 'url(/images/recruiting.svg) !important',
        },
        [createDataAttributes('icon', notRecruiting)]: {
            backgroundImage: 'url(/images/not-recruiting.svg) !important',
        },
        [createDataAttributes('icon', terminated)]: {
            backgroundImage: 'url(/images/terminated.svg) !important',
        },
    },
    smallIcon: {
        ...defaultIconStyle,
        height: '25px',
        width: '25px',
    },
    mediumIcon: {
        ...defaultIconStyle,
        height: '45px',
        width: '45px',
    },
});
