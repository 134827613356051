import { Condition } from 'components/fetch/models/studyDetails';
import * as React from 'react';
import { format, StringsSubset } from 'components/locale';
import { LocaleStrings } from 'components/locale/strings';

export const getAgeLabel = (minAge?: string, maxAge?: string) => {
    const isEmpty = (value?: string) =>
        value === undefined || value === null || value === 'N/A';
    if (isEmpty(minAge)) {
        minAge = '0';
    }
    if (isEmpty(maxAge)) {
        return `${minAge}+`;
    }
    return `${minAge}-${maxAge}`;
};

export const getConditionLabel = (conditions: Condition[], strings: LocaleStrings['common']) => {      
    const label =
        conditions &&
        conditions
            .map(condition => condition.DisplayValue)
            .filter((val,id,array) => array.indexOf(val) == id)
            .filter(value => value && value !== 'Unmapped')
            .join(', ');
    return label ? label : strings.notAvailable;
};

export const getConditionHeader = (conditions: Condition[], strings: StringsSubset<'common' | 'studyDetails'>) => {
    return getConditionLabel(conditions, strings.common);
};

export const getLookupConditionLabel = (conditions: Condition[]) => {      
    const label =
        conditions &&
        conditions
            .map(condition => condition.LookupValue)
            .toString()
            .split(',')
            .filter((val,id,array) => array.indexOf(val) == id)
            .filter(value => value && value !== 'Unmapped')
            .filter(value => value && value !== 'COVID-19')
            .filter(value => value && value !== 'COVID19')
            .join(', ');
    return label;
};
