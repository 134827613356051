export const faqsPage = {
    faqsItems: [
        {
            title: "What is a clinical trial?",
            description: "A clinical trial (also called clinical research) is a research study in human volunteers to answer specific health questions. Carefully conducted clinical trials are the fastest and safest way to find treatments that work in people and ways to improve health. Interventional trials determine whether experimental treatments or new ways of using known therapies are safe and effective under controlled environments. Observational trials address health issues in large groups of people or populations in natural settings."
        },
        {
            title: "Why participate in a clinical trial?",
            description: "Participants in clinical trials can play a more active role in their own health care, gain access to new research treatments before they are widely available, and help others by contributing to medical research."
        },
        {
            title: "Who can participate in a clinical trial?",
            description: "All clinical trials have guidelines about who can participate. Using inclusion/exclusion criteria is an important principle of medical research that helps to produce reliable results. The factors that allow someone to participate in a clinical trial are called “inclusion criteria” and those that disallow someone from participating are called “exclusion criteria”. These criteria are based on such factors as age, gender, the type and stage of a disease, previous treatment history, and other medical conditions. Before joining a clinical trial, a participant must qualify for the study. Some research studies seek participants with illnesses or conditions to be studied in the clinical trial, while others need healthy participants. It is important to note that inclusion and exclusion criteria are not used to reject people personally. Instead, the criteria are used to identify appropriate participants and keep them safe. The criteria help ensure that researchers will be able to answer the questions they plan to study."
        },
        {
            title: "What is informed consent?",
            description: "Informed consent is the process of learning the key facts about a clinical trial before deciding whether or not to participate. It is also a continuing process throughout the study to provide information for participants. To help someone decide whether or not to participate, the doctors and nurses involved in the trial explain the details of the study. If the participant’s native language is not English, translation assistance can be provided. Then the research team provides an informed consent document that includes details about the study, such as its purpose, duration, required procedures, and key contacts. Risks and potential benefits are explained in the informed consent document. The participant then decides whether or not to sign the document. Informed consent is not a contract, and the participant may withdraw from the trial at any time."
        },
        {
            title: "What are the benefits and risks of participating in a clinical trial?",
            description: "Clinical trials that are well-designed and well-executed are the best approach for participants to:\n\n\u2022\t\tPlay an active role in their own health care.\n\u2022\t\tGain access to new research treatments before they are widely available.\n\u2022\t\tHelp others by contributing to medical research.\n\nThere are also risks to clinical trials:\n\n\u2022\t\tThere may be unpleasant, serious or even life-threatening side effects to treatment.\n\u2022\t\tThe treatment may not be effective for the participant.\n\u2022\t\tThe protocol may require more of their time and attention than would a non-protocol treatment, including trips to the study site, more treatments, hospital stays or complex dosage requirements            "
        },
        {
            title: "What are side effects and adverse reactions?",
            description: "Side effects are any undesired actions or effects of drug or treatment. Negative or adverse effects may include headache, nausea, hair loss, skin irritation, or other physical problems. Experimental treatments must be evaluated for both immediate and long-term side effects."
        },
        {
            title: "How is the safety of the participant protected?",
            description: "The ethical and legal codes that govern medical practice also apply to clinical trials. In addition, most clinical research is federally regulated with built in safeguards to protect the participants. The trial follows a carefully controlled protocol, a study plan which details what researchers will do in the study. As a clinical trial progresses, researchers report the results of the trial at scientific meetings, to medical journals, and to various government agencies. Individual participants’ names will remain secret and will not be mentioned in these reports (See Confidentiality Regarding Trial Participants)."
        },
        {
            title: "What should people consider before participating in a trial?",
            description: "People should know as much as possible about the clinical trial and feel comfortable asking the members of the health care team questions about it, the care expected while in a trial, and the cost of the trial. The following questions might be helpful for the participant to discuss with the health care team. Some of the answers to these questions are found in the informed consent document.\n\n\u2022\t\tWhat is the purpose of the study?\n\u2022\t\tWho is going to be in the study?\n\u2022\t\tWhy do researchers believe the new treatment being tested may be effective?\n\u2022\t\tHas it been tested before?\n\u2022\t\tWhat kinds of tests and treatments are involved?\n\u2022\t\tHow do the possible risks, side effects, and benefits in the study compare with my current treatment?\n\u2022\t\tHow might this trial affect my daily life?\n\u2022\t\tHow long will the trial last?\n\u2022\t\tWill hospitalization be required?\n\u2022\t\tWho will pay for the treatment?\n\u2022\t\tWill I be reimbursed for other expenses?\n\u2022\t\tWhat type of long-term follow up care is part of this study?\n\u2022\t\tHow will I know that the treatment is working?\n\u2022\t\tWill results of the trials be provided to me?\n\u2022\t\tWho will be in charge of my care?"
        },
        {
            title: "What kind of preparation should a potential participant make for the meeting with the research coordinator or doctor?",
            description: "\u2022\t\tPlan ahead and write down possible questions to ask.\n\u2022\t\tAsk a friend or relative to come along for support and to hear the responses to the questions.\n\u2022\t\tBring a tape recorder to record the discussion to replay later.\n\nEvery clinical trial in the U.S. must be approved and monitored by an Institutional Review Board (IRB) to make sure the risks are as low as possible and are worth any potential benefits. An IRB is an independent committee of physicians, statisticians, community advocates, and others that ensures that a clinical trial is ethical and the rights of study participants are protected. All institutions that conduct or support biomedical research involving people must, by federal regulation, have an IRB that initially approves and periodically reviews the research."
        },
        {
            title: "Does a participant continue to work with a primary health care provider while in a trial?",
            description: "Yes. Most clinical trials provide short-term treatments related to a designated illness or condition, but do not provide extended or complete primary health care. In addition, by having the health care provider work with the research team, the participant can ensure that other medications or treatments will not conflict with the protocol."
        },
        {
            title: "Can a participant leave a clinical trial after it has begun?",
            description: "Yes. A participant can leave a clinical trial, at any time. When withdrawing from the trial, the participant should let the research team know about it, and the reasons for leaving the study."
        },
        {
            title: "Where do the ideas for trials come from?",
            description: "Ideas for clinical trials usually come from researchers. After researchers test new therapies or procedures in the laboratory and in animal studies, the treatments with the most promising laboratory results are moved into clinical trials. During a trial, more and more information is gained about a new treatment, its risks and how well it may or may not work."
        },
        {
            title: "Who sponsors clinical trials?",
            description: "Clinical trials are sponsored or funded by a variety of organizations or individuals such as physicians, medical institutions, foundations, voluntary groups, and pharmaceutical companies, in addition to federal agencies such as the National Institutes of Health (NIH), the Department of Defense (DOD), and the Department of Veteran’s Affairs (VA). Trials can take place in a variety of locations, such as hospitals, universities, doctors’ offices, or community clinics."
        },
        {
            title: "What is a protocol?",
            description: "A protocol is a study plan on which all clinical trials are based. The plan is carefully designed to safeguard the health of the participants as well as answer specific research questions. A protocol describes what types of people may participate in the trial; the schedule of tests, procedures, medications, and dosages; and the length of the study. While in a clinical trial, participants following a protocol are seen regularly by the research staff to monitor their health and to determine the safety and effectiveness of their treatment."
        },
        {
            title: "What is a placebo?",
            description: "A placebo is an inactive pill, liquid, or powder that has no treatment value. In clinical trials, experimental treatments are often compared with placebos to assess the treatment’s effectiveness. In some studies, the participants in the control group will receive a placebo instead of an active drug or treatment."
        },
        {
            title: "What is a control or control group?",
            description: "A control is the standard by which experimental observations are evaluated. In many clinical trials, one group of patients will be given an experimental drug or treatment, while the control group is given either a standard treatment for the illness or a placebo."
        },
        {
            title: "What are the different types of clinical trials?",
            description: "\u2022\t\tTreatment trials test new treatments, new combinations of drugs, or new approaches to surgery or radiation therapy.\n\u2022\t\tPrevention trials look for better ways to prevent disease in people who have never had the disease or to prevent a disease from returning. These approaches may include medicines, vitamins, vaccines, minerals, or lifestyle changes.\n\u2022\t\tDiagnostic trials are conducted to find better tests or procedures for diagnosing a particular disease or condition.\n\u2022\t\tScreening trials test the best way to detect certain diseases or health conditions.\n\u2022\t\tQuality of Life trials (or Supportive Care trials) explore ways to improve comfort and the quality of life for individuals with a chronic illness."
        },
        {
            title: "What are the phases of clinical trials?",
            description: "Clinical trials are conducted in phases. The trials at each phase have a different purpose and help scientists answer different questions:\n\n\u2022\t\t  In Phase I trials, researchers test a new drug or treatment in a small group of people (20-80) for the first time to evaluate its safety, determine a safe dosage range, and identify side effects.\n\u2022\t\t  In Phase II trials, the study drug or treatment is given to a larger group of people (100-300) to see if it is effective and to further evaluate its safety.\n\u2022\t\t  In Phase III trials, the study drug or treatment is given to large groups of people (1,000-3,000) to confirm its effectiveness, monitor side effects, compare it to commonly used treatments, and collect information that will allow the drug or treatment to be used safely.\n\u2022\t\t  In Phase IV trials, post marketing studies delineate additional information including the drug’s risks, benefits, and optimal use."
        },
        {
            title: `What is an "expanded access" protocol?`,
            description: `Most human use of investigational new drugs takes place in controlled clinical trials conducted to assess safety and efficacy of new drugs. Data from the trials can serve as the basis for the drug marketing application. Sometimes, patients do not qualify for these carefully-controlled trials because of other health problems, age, or other factors. For patients who may benefit from the drug use but don’t qualify for the trials, FDA regulations enable manufacturers of investigational new drugs to provide for “expanded access” use of the drug. For example, a treatment IND (Investigational New Drug application) or treatment protocol is a relatively unrestricted study.\n\nThe primary intent of a treatment IND/protocol is to provide for access to the new drug for people with a life-threatening or serious disease for which there is no good alternative treatment. A secondary purpose for a treatment IND/protocol is to generate additional information about the drug, especially its safety. Expanded access protocols can be undertaken only if clinical investigators are actively studying the new treatment in well-controlled studies, or all studies have been completed. There must be evidence that the drug may be an effective treatment in patients like those to be treated under the protocol. The drug cannot expose patients to unreasonable risks given the severity of the disease to be treated.\n\nSome investigational drugs are available from pharmaceutical manufacturers through expanded access programs listed in ClinicalTrials.gov. Expanded access protocols are generally managed by the manufacturer, with the investigational treatment administered by researchers or doctors in office-based practice. If you or a loved one are interested in treatment with an investigational drug under an expanded access protocol listed in ClinicalTrials.gov, review the protocol eligibility criteria and location information and inquire at the Contact Information number.`
        }
    ]
}