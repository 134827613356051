import React, { ReactNode } from 'react';
import { TenantModel } from 'src/model';
import { FetchTenant } from 'components/fetch/tenant';
import FullscreenLoading from 'components/loading/fullscreenLoading';
import { TenantContext } from 'components/loadTenant/tenantContext';

interface Props {
    children: (cfg: TenantModel) => ReactNode;
    location: Location;
}

export class LoadTenant extends React.Component<Props> {
    render = () => {
        const { children, location } = this.props;

        return (
            <FetchTenant host={location.host}>
                {rq => {
                    if (rq.data) {
                        return (
                            <TenantContext.Provider value={rq.data}>
                                {children(rq.data)}
                            </TenantContext.Provider>
                        );
                    }
                    if (rq.error) {
                        console.error('Error loading tenant', rq.error);
                        return <p>Error</p>;
                    }
                    return <FullscreenLoading />;
                }}
            </FetchTenant>
        );
    };
}
