import * as React from 'react';
import './loading.css';
import classNames from 'classnames';
import { Theme } from 'components/common/theme/default';
import { CSSProperties } from 'react';
import { WithTheme, withTheme } from '@material-ui/styles';

type SpinnerType = 'absolute-centered' | 'default' | 'centered';

interface Props extends WithTheme<Theme> {
    type?: SpinnerType;
    color?: string | { fromTheme: keyof Theme['color'] };
}

class Loading extends React.Component<Props> {
    render() {
        const { type } = this.props;
        const style = this.getStyle();
        return (
            <div
                className={classNames('lds-facebook', {
                    'lds-facebook--absolute': type === 'absolute-centered',
                    'lds-facebook--centered':
                        type === 'centered' || 'absolute-centered',
                })}
            >
                <div style={style} />
                <div style={style} />
                <div style={style} />
            </div>
        );
    }

    getStyle(): CSSProperties | undefined {
        const { color, theme } = this.props;
        if (!color) return undefined;
        if (typeof color === 'string') {
            return { background: color };
        }
        if (typeof color === 'object' && 'fromTheme' in color) {
            return { background: theme.color[color.fromTheme] };
        }

        return undefined;
    }
}

export default withTheme(Loading);
