import { LocaleSource } from 'src/model';

const empty = Promise.resolve({});

export const resolveLocaleSource = (
    localeSrc: LocaleSource
): Promise<object | 'default'> => {
    if (!localeSrc) {
        console.log('Error on resolving locale');
        return empty;
    }

    if ('url' in localeSrc) {
        return fetch(localeSrc.url).then(rsp => {
            if (rsp.ok) return rsp.json();
            console.error('Error on fetching locale');
            return empty;
        });
    }

    if ('data' in localeSrc) {
        return Promise.resolve(localeSrc.data);
    }
    console.error('Error on resolving locale');
    return empty;
};
