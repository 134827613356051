export const homePage = {
    title: 'Coronavirus Clinical Trials',
    ongoingTrials: ['<p><b>{trialsCount} ongoing clinical trials</b> for coronavirus control.</p>', ['trialsCount'], 'html'],
    keywordLabel: 'Keyword',
    keywordPlaceholder: 'Search by Keyword',
    locationLabel: 'Location',
    locationPlaceholder: 'Search by location',
    updateSearch: 'Search',
    improveSearch: 'For a more refined search use:\n',
    advancedSearch: 'Advanced Search',
    welcomeText: {
        paragraph_1: 'This website is provided as a free global resource to both researchers and the general public on COVID-19 clinical trials. Check this website regularly for new COVID-19 trials, as this information is automatically updated from ClinicalTrials.gov.',
        paragraph_2: 'At TrialScope, we are committed to clinical trial transparency and data sharing, particularly during these difficult times.',
    },
    whatIsCTSection: {
        title: 'What is a Clinical Trial?',
        text: 'Clinical trials are a type of research that studies new tests and treatments and evaluates their effects on human health outcomes. People volunteer to take part in clinical trials to test medical interventions including drugs, cells and other biological products, surgical procedures, radiological procedures, devices, behavioral treatments and preventive care.',
    },
    whatIsCOVIDSection: {
        title: 'What is Coronavirus?',
        paragraph_1: 'Coronaviruses (CoV) are a large family of viruses that cause illness ranging from the common cold to more severe diseases such as Middle East Respiratory Syndrome (MERS-CoV) and Severe Acute Respiratory Syndrome (SARS-CoV).',
        paragraph_2: ['<a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" target="_blank" rel="noopener noreferrer">Coronavirus disease (COVID-19)</a> is a new strain discovered in 2019 that had not been previously identified in humans.','html'],
    },
    learnMoreSection: {
        graphTitle: 'Symptoms',
        graphSubtitle: 'What is known so far',
        graphText: 'Cases ranging from mild to severe.',
        mildCases: 'Mild cases can include',
        severeCases: 'Severe cases can include',
        cases: {
            mild: ["Fever", "Cough", "Shortness of Breath"],
            severe: ["Pneumonia", 'Kidney Failure', 'Death'],
        },
        source: ['Source:\n <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" target="_blank" rel="noopener noreferrer">World Health Organization</a>','html'],
    },
    videoSection: {
        videoCode: 'mOV1aBVYKGA',
        title: 'Novel Coronavirus\n (2019-nCoV)',
        text: 'Watch this short video about Coronavirus (2019-nCoV) to find out more.',
        source: ['Source:\n <a href="https://www.youtube.com/channel/UC07-dOwgza1IguKA86jqxNA" target="_blank" rel="noopener noreferrer">World Health Organization Youtube Channnel</a>','html'],
    },
    bannerSection: {
        title: 'Before making the decision to participate in a clinical trial, it is important to learn about trials and what it means to be a study participant.',
        subtitle: ['View the <b>FAQ</b> and <b>Questions to Ask</b> sections on the FOR PATIENTS page.','html'],
        resource: 'Learn About Clinical Trials',
        provider: 'Site, education material provided by:',
        text: 'The Center for Information and Study on Clinical Research Participation (CISCRP) is an award-winning nonprofit organization dedicated to educating and engaging the public, patients, research and health care professionals as partners in the clinical research process.',
    }
} as const;
