import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from './button.styles';
import React from 'react';
import { Button as BootstrapButton, ButtonProps } from 'reactstrap';
import classNames from 'classnames';

interface Props
    extends WithStyles<typeof styles>,
        Omit<ButtonProps, 'innerRef'> {}

class Button extends React.Component<Props> {
    render = () => {
        const { classes, className } = this.props;
        const fullClassName = classNames(classes.searchButton, className);
        return (
            <BootstrapButton
                {...{
                    ...this.props,
                    className: fullClassName,
                    classes: undefined,
                }}
            />
        );
    };
}

export default withStyles(styles)(Button);
