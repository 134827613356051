export const widgetStrings = {
    findTrialNearYou: 'Find a Trial Near You',

    medicalConditionLabel: 'Medical Condition',
    medicalConditionsHeader: 'Medical Conditions',
    medicalConditionPlaceholder: 'Select Medical Condition',

    keywordsSearchPlaceHolder: 'Search by keyword, condition, product, etc.',
    locationPlaceHolder: 'Location',
    distanceLabel: 'Distance',
    applyLabel: 'Apply',
    searchLabel: 'Search',
    moreSearchOptions: 'More Search Options >',
};

export default widgetStrings;
export type WidgetStrings = typeof widgetStrings;
