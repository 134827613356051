import { createStyles } from "@material-ui/styles";

export const styles = createStyles({
  container: {
    width: "100%",
    display: "block",
    textAlign: "right",
  },
  button: {
    display: "inline",
    cursor: "pointer",
    margin: "auto",
    marginLeft: "15px",
  },
  shareText: {
    lineHeight: "22px",
  },
  buttonsContainer: {
    paddingTop: "5px",
    minWidth: "250px",
    "& svg": {
      "&:focus": {
        outline: "1px auto",
      },
    },
  },
});
