export const search = {
    advancedSearch: 'Advanced Search',
    accurateResult: 'Appling filters bellow will refine your search and give you a more accurate result.',
    andMore: [' (and {count} more)', ['count']],
    findTrialNearYou: 'Find a Trial Near You',
    selectConditions: 'Select Medical Condition(s)',

    medicalConditionLabel: 'Medical Condition',
    medicalConditionsHeader: 'Medical Conditions',
    medicalConditionPlaceholder: 'Select Medical Condition',

    distanceLabel: 'Distance',
    applyLabel: 'Apply',
    searchLabel: 'Search',
    moreSearchOptions: 'More Search Options >',

    filter: {
        recruitingInfo: 'The study is open and recruiting patients.',
        notRecruitingInfo: 'The study is not recruiting patients.',
        studyCompletedInfo:
            'The study is finished and not recruiting participants.<br/>However, the results may be available on this site.',
        terminatedWithdrawnInfo:
            'The study has stopped early and will not start again. Participants are no longer being examined or treated.',
    }
} as const;
