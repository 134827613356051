import { default as React, ReactNode } from 'react';

interface LoadingResolverProps<T = any> {
    children: (value: {
        data?: T;
        error: any;
        isLoading: boolean;
    }) => ReactNode | null;
    onDidUpdate?: () => void;
    promise: Promise<T>;
}

interface State<T = any> {
    promise: Promise<T>;
    error?: any;
    resolved: boolean;
    resolving: boolean;
    didUpdate: boolean;
    data?: T;
}

export class Async<TValue = any> extends React.Component<
    LoadingResolverProps<TValue>,
    State<TValue>
> {
    static getDerivedStateFromProps(
        props: LoadingResolverProps,
        state: State
    ): State | null {
        if (props.promise !== state.promise) {
            return {
                promise: props.promise,
                resolved: false,
                resolving: false,
                didUpdate: false,
            };
        }
        return null;
    }

    isActive: boolean;

    state: State<TValue> = {
        resolved: false,
        resolving: false,
        didUpdate: false,
        promise: Promise.resolve(null as any),
    };

    constructor(props: LoadingResolverProps<TValue>, context: any) {
        super(props, context);
        this.isActive = true;
    }

    componentDidUpdate(): void {
        this.updateState();
    }

    componentDidMount(): void {
        this.updateState();
    }

    componentWillUnmount = () => (this.isActive = false);

    updateState = () => {
        const { resolved, resolving, didUpdate, promise } = this.state;

        if (!this.isActive) {
            return;
        }

        if (resolved && !didUpdate && this.props.onDidUpdate) {
            this.props.onDidUpdate();
            this.setState({ didUpdate: true });
        }

        if (resolved || resolving) {
            return;
        }
        const resultPromise = resolvePromise(promise);
        if (resultPromise.sync.resolved) {
            this.setState({ promise, ...resultPromise.sync });
        } else {
            resultPromise.then(
                data => this.isActive && this.setState({ promise, ...data })
            );
            this.setState({ resolving: true });
        }
    };

    render = () => {
        const { children } = this.props;
        const { resolved, data, error } = this.state;

        return children({ isLoading: !resolved, data: data!, error });
    };

    shouldComponentUpdate(props: LoadingResolverProps<TValue>, prevState: State) {
        const { resolved, data, error } = this.state;
        return prevState.resolved !== resolved
            || prevState.data !== data
            || prevState.error !== error;
    }
}

function resolvePromise<T>(promise: Promise<T>) {
    let data: any;
    let resolved = false;
    let error: any;
    const result = promise
        .then(v => {
            resolved = true;
            data = v;
            return v;
        })
        .catch(e => {
            resolved = true;
            error = e;
            throw e;
        })
        .then(() => ({ resolved, data, error }));
    return Object.assign(result, { sync: { resolved, data, error } });
}
