import { createStyles } from '@material-ui/styles';
import { commonStyles } from 'components/common/theme/styles';
import { Theme } from 'components/common/theme/default';

const closeBtnSz = '17px';

export const styles = (theme: Theme) => createStyles({
    content: {
        boxShadow: '0 30px 40px 0 rgba(0,0,0,0.2)',
        padding: '60px 60px 20px 60px',
        border: 'none',
        minHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px 20px 30px',
        }
    },
    modal: {
        maxWidth: '1094px',
    },
    modalBody:{
        padding:'0!important',
    },
    backdrop: {
        background: '#fff',
    },
    header: {
        padding: '0 0 1em 0',
        border: 'none',
        '& h5': {
            ...commonStyles(theme).headerTitleLight,
            margin: 0,
            padding: '0 0 30px 0',
        },
    },
    closeButton: {
        background: 'url(/images/CloseIcon.png) no-repeat center center',
        backgroundSize: `${closeBtnSz} ${closeBtnSz}`,
        position: 'absolute',
        height: closeBtnSz,
        width: closeBtnSz,
        right: 44,
        top: 44,
        cursor: 'pointer',
        zIndex: 1000,
    },
});
