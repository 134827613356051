import * as React from 'react';
import { LocaleValue, LocaleContext } from './localeContext';
import { TenantModel } from 'src/model';
import { Async } from 'components/async/async';
import { TenantDataResolver } from 'src/services/tenantDataResolver';
import Loading from 'components/loading/loading';
import { LocaleStrings, localeStrings } from 'components/locale/strings';
import * as merge from 'deepmerge';
import * as formatTemplate from 'string-template';
import * as queryString from 'query-string';

const globalFnName = 'ChangeLocale';
const localStorageKey = 'locale';
const localeAttribute = 'data-locale';

interface LocaleState {
    locale: string;
    prevLocale: string | null;
}

interface Props {
    children: (localeValue: LocaleValue) => React.ReactNode;
    resolver: TenantDataResolver;
    tenant: TenantModel;
    location: Location;
}

export class LocaleProvider extends React.Component<Props, LocaleState> {
    state: LocaleState;

    constructor(props: Props, context: any) {
        super(props, context);
        const query = queryString.parse(props.location.search);
        const locale = query['lang'] as string || localStorage.getItem(localStorageKey) || 'en';
        localStorage.setItem(localStorageKey, locale);
        this.state = {
            locale,
            prevLocale: null,
        };
    }

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<LocaleState>
    ): boolean {
        return this.state.locale !== nextState.locale;
    }

    render = () => {
        const { locale, prevLocale } = this.state;
        const { resolver, tenant } = this.props;
        const localesPromise = resolver.resolveLocaleSource(
            tenant.locales[locale]
        );
        const defaultLocalePromise = locale === 'en'
            ? localesPromise
            : tenant.locales['en']
                ? resolver.resolveLocaleSource(tenant.locales['en'])
                : Promise.resolve({});
        const promise = Promise.all([localesPromise, defaultLocalePromise]);
        return (
            <Async promise={promise}>
                {({ isLoading, data, error }) => {
                    let [ locales, defaultLocales ] = data! || [{}, {}];
                    if (locales === 'default') {
                        locales = {};
                    }
                    if (defaultLocales === 'default') {
                        defaultLocales = {};
                    }

                    if (isLoading) {
                        if (prevLocale) {
                            return this.renderChildren(prevLocale!, locales!, defaultLocales!);
                        } else {
                            return <Loading />;
                        }
                    }
                    if (error) {
                        console.error('locale fetching error', error);
                        return 'Error';
                    }
                    return this.renderChildren(locale, locales!, defaultLocales!);
                }}
            </Async>
        );
    };

    renderChildren = (locale: string, strings: Partial<LocaleStrings>, customDefaults: Partial<LocaleStrings>) => {
        const { children, tenant } = this.props;
        const defaultStrings = localeStrings[locale] || localeStrings['en'];
        const allStrings = merge.all([defaultStrings, customDefaults, strings]) as any;
        const localeValue: LocaleValue = {
            locale,
            strings: allStrings,
            allLocales: Object.keys(tenant.locales),
            changeLocaleFnName: globalFnName,
            changeLocale: window[globalFnName],
            updateDom: this.updateSelects,
            localize: (key, args) => {
                if (!key) return '';
                let node: any = allStrings;
                for (const k of key.split('.')) {
                    if (node && node.hasOwnProperty(k)) {
                        node = node[k];
                    } else {
                        console.warn(`Cannot resolve ${key}.`);
                        return key;
                    }
                }

                if (node && node instanceof Array) {
                    return formatTemplate(node[0], args);
                }

                if (!node || typeof node !== 'string') {
                    console.warn(`Cannot resolve ${key}.`);
                    return key;
                }

                return node;
            }
        };
        return (
            <LocaleContext.Provider value={localeValue}>
                {children(localeValue)}
            </LocaleContext.Provider>
        );
    };

    componentDidMount(): void {
        window[globalFnName] = this.changeLocale;
        this.attachGlobalHandlers();
    }

    componentDidUpdate(): void {
        this.updateSelects();
    }

    private changeLocale = (locale: string) => {
        this.setState({ locale, prevLocale: locale });
        localStorage.setItem(localStorageKey, locale);
        return locale;
    };

    private attachGlobalHandlers = () => {
        document.addEventListener('click', ev => {
            if (ev.target) {
                const el = ev.target as HTMLElement;
                if (
                    el.hasAttribute &&
                    el.hasAttribute(localeAttribute) &&
                    el.tagName !== 'SELECT' &&
                    el.tagName !== 'OPTION'
                ) {
                    const lang = el.getAttribute(localeAttribute)!;
                    this.changeLocale(lang);
                }
            }
        });

        document.addEventListener('change', ev => {
            if (ev.target) {
                const el = ev.target as HTMLSelectElement;
                if (
                    el.tagName === 'SELECT' &&
                    el.hasAttribute(localeAttribute)
                ) {
                    this.changeLocale(el.value);
                }
            }
        });
    };

    private updateSelects = (locale?: string) => {
        locale = locale || this.state.locale;
        document
            .querySelectorAll(`select[${localeAttribute}]`)
            .forEach((select: HTMLSelectElement) => {
                for (let idx = 0; idx < select.length; idx++) {
                    if (select.options[idx].value === locale) {
                        select.options[idx].selected = true;
                        return;
                    }
                }
            });
    };
}
