import * as React from 'react';
import { LocaleStrings } from 'components/locale/strings';

export interface LocaleValue {
    locale: string;
    changeLocaleFnName: string;
    changeLocale: (locale: string) => void;
    updateDom: () => void;
    strings: LocaleStrings;
    allLocales: string[];
    localize: (value?: string, args?: any) => string;
}

export const LocaleContext = React.createContext<LocaleValue>({
    locale: 'en',
    changeLocale: () => {},
    changeLocaleFnName: '',
    updateDom: () => {},
    strings: {} as any,
    allLocales: [],
    localize: s => s || ''
});
