import * as React from 'react';
import { TenantModel } from 'src/model';
import { TenantDataResolver } from 'src/services/tenantDataResolver';
import EjsTemplateComponent from 'components/ejsTemplateComponent/ejsTemplateComponent';

interface Props {
    tenant: TenantModel;
    tenantResolver: TenantDataResolver;
}

class Header extends React.Component<Props> {
    render = () => {
        const { tenant, tenantResolver } = this.props;
        return (
            <div className='header--container'>
                <EjsTemplateComponent
                    tenant={tenant}
                    templateSrc={
                        tenant.header || {
                            url: '/templates/default/header.ejs',
                        }
                    }
                    resolver={tenantResolver}
                />
            </div>
        );
    };
}

export default Header;
