import * as React from 'react';
import { TenantModel } from 'src/model';
import { TenantDataResolver } from 'src/services/tenantDataResolver';
import EjsTemplateComponent from 'components/ejsTemplateComponent/ejsTemplateComponent';

interface Props {
    tenant: TenantModel;
    tenantResolver: TenantDataResolver;
}

class Footer extends React.Component<Props> {
    render = () => {
        const { tenant, tenantResolver } = this.props;
        return (
            <div className='footer--container'>
                <EjsTemplateComponent
                    tenant={tenant}
                    templateSrc={
                        tenant.footer || {
                            url: '/templates/default/footer.ejs',
                        }
                    }
                    resolver={tenantResolver}
                />
            </div>
        );
    };
}

export default Footer;
