import * as React from 'react';
import { LocaleContext, LocaleValue } from 'components/locale/localeContext';
import { LocaleStrings } from 'components/locale/strings';
import * as formatTemplate from 'string-template';
import parseHtml from 'html-react-parser';

export interface Format {
    (key: string): string;
    <TArgs extends string>(value: readonly [string], args?: any): string;
    (value: readonly [string, 'html']): React.ReactNode;
    <TArgs extends string>(value: readonly [string, readonly TArgs[]], args: { [key in TArgs]: any}): string;
    <TArgs extends string>(value: readonly [string, readonly TArgs[], 'html'], args: { [key in TArgs]: any}): React.ReactNode;
}

type ExtractStrings<T> = T extends (strings: LocaleStrings) => infer S ? S : T;

export interface WithStrings<TOwnStrings = LocaleStrings> {
    strings: ExtractStrings<TOwnStrings>;
    format: Format;
}

export type StringsSubset<T extends keyof LocaleStrings> = { [key in T]: LocaleStrings[key]};

export const format: Format = (value: any, args?: any) => {
    if (!value) return value;
    if (value instanceof Array) {
        if (!value.length) {
            return '';
        }
        const formattedValue = formatTemplate(value[0], args);
        if (value[1] && args) {
            const missingKeys = Object.keys(args).filter(k => value[1].indexOf(k) < 0);
            if (missingKeys.length > 0) {
                console.log('missing template keys', missingKeys, value, args);
            }
        }
        if (value[1] === 'html' || value[2] === 'html') {
            return parseHtml(formattedValue);
        }
        return formattedValue;
    }
    return formatTemplate(value, args);
};

export function withStrings<TProps extends WithStrings<TStrings>, TStrings = LocaleStrings>(
    Wrapped: React.ComponentType<TProps>,
    ownStringsFn?: (strings: LocaleStrings) => TStrings
) {
    return class extends React.Component<Omit<TProps, keyof WithStrings>> {

        private lang: string = '';
        private strings: any = {};

        render = () => {
            return (
                <LocaleContext.Consumer>
                    {locale => {
                        return <Wrapped
                            {...({
                                ...this.props,
                                strings: this.getStrings(locale),
                                format
                            } as any)}
                        />;
                    }}
                </LocaleContext.Consumer>
            );
        };

        getStrings = (localeValue: LocaleValue) => {
            if (this.lang !== localeValue.locale) {
                let strings: any = localeValue.strings;
                if (ownStringsFn) {
                    strings = ownStringsFn(localeValue.strings);
                }
                this.strings = strings;
                this.lang = localeValue.locale;
            }
            return this.strings;
        }
    };
}
