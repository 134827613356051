import createBreakpoints from './createBreakpoints';

export const defaultTheme = {
    fontFamily:
        '"Open Sans", sans-serif, Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '16px',
    color: {
        backdropBackground: '#f9f9f9',
        background: '#00ACD7',
        advancedBackground: '#F4F3F3',
        filtersBackground: '#f9f9f9',
        text: '#FFFFFF',
        inputBorder: '#FFFFFF',
        inputText: '#6D7278',
        searchButton: '#004A97',
        searchButtonHover: '#007bff',
        sectionName: '#333333',
        checkboxBorder: 'rgba(0,0,0,0.25)',
        borderColor: '#979797',
        separator: '#979797',
        error: '#CB4544',
        summaryLabel: '#000000',
        title: '#004A97',
        dropdownBorder: '#B3B3B3',
        icon: '#00ACD7'
    },
    breakpoints: createBreakpoints({
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    }),
};

export type Theme = typeof defaultTheme;
