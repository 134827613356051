import "bootstrap/dist/css/bootstrap.css";
import "static/styles/index.css";

import * as React from "react";
import { LocaleProvider } from "components/locale/localeProvider";
import { TenantDataResolver } from "src/services/tenantDataResolver";
import { LoadTenant } from "components/loadTenant/loadTenant";
import { LocaleValue } from "components/locale/localeContext";
import { TenantCssModel, TenantModel } from "src/model";
import { Helmet } from "react-helmet";
import { LocaleStrings } from "components/locale/strings";
import LoadTheme from "components/layout/loadTheme";
import Page from "components/layout/page";

interface Props {
  children: React.ReactNode;
  location: Location;
  title?: string;
  description?: string;
  tenantCss?: keyof TenantCssModel;
}

// NOTE: this object contains cache that should be reused cross-page
//       so dynamic data (header/footer) will be fetched instantly from cache
const tenantResolver = new TenantDataResolver();

class Layout extends React.Component<Props> {
  componentDidMount() {
    // Add Orbita Assets
    const orbitaScript = document.createElement("script");
    orbitaScript.type = "text/javascript";
    orbitaScript.src =
      "https://assets-engage.orbita.cloud/chatbot/engage-bot-plugin.js";
    document.body.appendChild(orbitaScript);
    // Overwrite Orbita Settings and load the Chat
    setTimeout(() => {
      if (typeof window !== "undefined" && (window as any).orbita) {
        var orbita = (window as any).orbita;
        (orbita.mobileBotUrl = "/chat-bot"),
          (orbita.settings = {
            header: {
              logoUrl: "/chatbot/v2/assets/branding-mark.svg",
              headerText: "COVID-19",
            },
            theme: {
              dockFloat: {
                buttonSrc:
                  "https://assets-engage.orbita.cloud/img/chatbutton.png",
                buttonTitle: "My Button Title",
              },
            },
          });
        orbita.load();
      }
    }, 250);
  }
  componentWillUnmount() {
    if (typeof document !== "undefined") {
      const scriptElements = Object.values(
        document.getElementsByTagName("script")
      );
      scriptElements.forEach((script) =>
        script.src ===
        "https://assets-engage.orbita.cloud/chatbot/engage-bot-plugin.js"
          ? document.body.removeChild(script)
          : null
      );
    }
  }
  render = () => {
    const { children, location, tenantCss } = this.props;
    return (
      <LoadTenant location={location}>
        {(tenant) => {
          return (
            <LocaleProvider
              tenant={tenant}
              location={location}
              resolver={tenantResolver}
            >
              {(locale) => (
                <LoadTheme tenant={tenant} tenantCss={tenantCss}>
                  {this.renderMetaProps(tenant, locale, location)}
                  <Page
                    tenant={tenant}
                    resolver={tenantResolver}
                    children={children}
                  />
                  <div id="chatWindow"></div>
                  <div className="ie11-chatbot">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://engage.orbita.cloud/oeapi/bot/hub/coronaclinicaltrials-com"
                    >
                      <img
                        src="https://assets-engage.orbita.cloud/img/chatbutton.png"
                        alt="chatbot icon"
                      />
                    </a>
                  </div>
                </LoadTheme>
              )}
            </LocaleProvider>
          );
        }}
      </LoadTenant>
    );
  };

  private renderMetaProps = (
    tenant: TenantModel,
    locale: LocaleValue,
    location: Location
  ) => {
    const { title, description } = this.props;
    const { logoUrl } = tenant;

    return (
      <Helmet title={title}>
        <html lang="en" prefix="og: http://ogp.me/ns#" />
        {title ? <meta property="title" content={title} /> : null}
        {title ? <meta content={title} property="og:title" /> : null}
        {title ? <meta content={title} name="twitter:title" /> : null}

        {description ? <meta content={description} name="description" /> : null}
        {description ? (
          <meta content={description} property="og:description" />
        ) : null}
        {description ? (
          <meta content={description} name="twitter:description" />
        ) : null}
        {logoUrl ? <meta property="og:image" content={logoUrl} /> : null}
        {logoUrl ? <meta property="og:url" content={location.href} /> : null}
        {logoUrl ? <meta property="og:type" content={"website"} /> : null}
      </Helmet>
    );
  };
}

export default Layout;
