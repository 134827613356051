import * as React from 'react';
import { WithStyles } from '@material-ui/styles';
import { styles } from 'components/search-results/filters/filters.styles';
import { FiltersSection } from 'components/filter/types';
import classNames from 'classnames';
import Checkbox, {
    Props as CheckboxProps,
} from 'components/common/checkbox/checkbox';
import { SearchModel } from 'src/model/searchModel';
import { FilterSectionHeader } from 'components/search-results/filters/filterSectionHeader';

interface Props extends WithStyles<typeof styles> {
    section: FiltersSection;
    searchModel: SearchModel;
    onSearchModelUpdated: (model: SearchModel) => void;
}

interface State {
    isOpen: boolean;
}

export class FilterSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: props.section.parameterName in props.searchModel.filters,
        };
    }

    render = () => {
        const { classes, section } = this.props;
        const { isOpen } = this.state;
        return (
            <>
            {section.sectionName !== 'Gender' ? (
                <div>
                <FilterSectionHeader
                    title={section.sectionName}
                    classes={classes}
                    isOpen={isOpen}
                    toggleOpen={this.toggleOpen}
                />
                {isOpen ? (
                    <div
                        className={classNames(
                            classes.checkboxContainer,
                            classes.row
                        )}
                    >
                        {section.items.map(item => (
                            <Checkbox
                                key={item.internalValue}
                                item={item}
                                parameterName={section.parameterName}
                                isChecked={this.isChecked(item.internalValue)}
                                onChanged={this.onChanged}
                            />
                        ))}
                    </div>
                ) : null}
                </div>
            ) : null}
            </>
        );
    };

    isChecked = (internalValue: string) => {
        const { section, searchModel } = this.props;
        if (
            section.parameterName in searchModel.filters &&
            searchModel.filters[section.parameterName] instanceof Array
        ) {
            return (
                searchModel.filters[section.parameterName]!.indexOf(
                    internalValue
                ) >= 0
            );
        }
        return false;
    };

    onChanged = (value: boolean, { parameterName, item }: CheckboxProps) => {
        if (!parameterName) {
            return;
        }
        const { searchModel, onSearchModelUpdated } = this.props;
        const filters = searchModel.filters;
        if (!filters[parameterName]) {
            filters[parameterName] = [];
        }
        const section = filters[parameterName] as string[];
        const index = section.indexOf(item.internalValue);
        if (value) {
            if (index < 0) {
                section.push(item.internalValue);
            }
        } else {
            if (index >= 0) {
                section.splice(index, 1);
            }
        }

        onSearchModelUpdated({ ...searchModel, filters: { ...filters } });
    };

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
}
