import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from './fullscreenLoading.styles';
import Loading from 'components/loading/loading';

interface Props extends WithStyles<typeof styles> {}

class FullscreenLoading extends React.Component<Props> {
    render = () => {
        const { classes } = this.props;
        return (
            <div className={classes.overlay}>
                <Loading type={'absolute-centered'} />
            </div>
        );
    };
}

export default withStyles(styles)(FullscreenLoading);
