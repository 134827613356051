import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    searchButton: {
        padding: '9px 0 10px 0',
        borderRadius: '6px',
        width: '100%',
        fontWeight: 600,
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '22px',
        backgroundColor: theme.color.searchButton,
        borderColor: theme.color.searchButton,
        '&:hover': {
            backgroundColor: theme.color.searchButtonHover,
            borderColor: theme.color.searchButtonHover,
        },
    },
});
