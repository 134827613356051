export const studyDetails = {

    title: 'Study Details',
    conditionTitle: ['Trial Condition(s): {title}', ['title']],

    trialCondition: {
        backToSearchResults: 'Back to Search Results',
        organizationIdentifier: 'Organization Identifier',
        clinicalTrialsGovIdentifier: 'ClinicalTrials.gov Identifier',

        ctGovIdentifierHeader: 'ClinicalTrials.gov',
        ctGovIdentifierInfo: 'The unique identification code given to each clinical study upon registration at ClinicalTrials.gov.',

        eudraCtHeader: 'EudraCT',
        eudraCtIdentifierInfo: 'The unique identification code given to each clinical study upon registration at EudraCT.',

        protocolIdHeader: 'Protocol ID',
        protocolIdInfo:
            'The unique identification code given by the study sponsor.',
        shareThisTrial: 'Share this trial'
    },

    trialLocation: {
        whereToParticipate: 'Where to Participate',
        location: 'Location',
        status: 'Status',
        noLocationForStudy: 'There are no locations found for this study.',
    },

    contactForm: {
        askToJoinInStudy: ['Ask to Join in Study - {title}', ['title']],
        contactUsForStudy: ['Contact Us for - {title}', ['title']],
        hintText: [`<p>Please fill out the form. Fields marked with an asterisk (*) are mandatory.</p>
<p>Please note that an answer to your request might only be possible if you provide an e-mail address or a telephone number.</p>`, 'html'],
        submitRequest: 'Submit Request',
        firstName: 'First Name',
        lastName: 'Last Name',
        yourMessage: 'Your Message',
        fieldIsRequired: ['{fieldName} is required', ['fieldName']],
        thankYouHeader: 'Thank you',
        receivedRequestBody:
            'We have received your request and shall get back to you as soon as we can. We encourage you to talk to your doctor to find out if joining a clinical trial is right for you.',
    },

    criteria: {
        inclusionCriteria: 'Inclusion Criteria',
        exclusionCriteria: 'Exclusion Criteria',
        inclusionAndExclusionCriteria: 'Inclusion & Exclusion Criteria',
    },

    trialPurpose: {
        trialPurpose: 'Trial Purpose',
        keyParticipationRequirements: 'Key Participation Requirements',
        requirementInformation: 'Requirement Information',
        inclusionInfo: 'The requirements that allow a person to participate in a clinical study.',
        exclusionInfo: 'The requirements that prevent a person to participate in a clinical study.'
    },

    trialSummary: {
        phase: 'Phase',
        studyType: 'Study Type',
        trialSummary: 'Trial Summary',
        medicalCondition: 'Medical Condition',
        trialDuration: 'Trial Duration',
        acceptsHealthyVolunteers: 'Accepts Healthy Volunteers',
        sponsor: 'Sponsor',
        present: 'Present',

        medicalConditionInfo:
            'The disease, disorder, syndrome, illness, or injury that is being studied.',
        trialDurationInfo:
            'The date on which the first participant is enrolled until the date the last participant´s last visit.',
        studyPhaseInfo:
            "The stage of a clinical trial studying a drug or biological product, based on definitions developed by the U.S. Food and Drug Administration (FDA). The phase is based on the study's objective, the number of participants, and other characteristics. There are five phases: Early Phase 1 (formerly listed as Phase 0), Phase 1, Phase 2, Phase 3, and Phase 4. Not Applicable is used to describe trials without FDA-defined phases, including trials of devices or behavioral interventions.",
        studyTypeInfo:
            'Study type describes the type of clinical trial. Clinical trial types include interventional studies (also called clinical trials), observational studies (including patient registries), and expanded access.',
        acceptHealthyInfo:
            'A type of eligibility criteria that indicates whether people who do not have the condition/disease being studied can participate in that clinical study.',
        sponsorInfo:
            'A person who takes responsibility for and initiates a clinical investigation. The sponsor may be an individual or pharmaceutical company, governmental agency, academic institution, private organization, or other organization.'
    }
} as const;
