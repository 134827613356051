import * as React from 'react';
import { GetStudyCodes } from 'components/fetch';
import { mapFilters } from 'components/filter/filtersMapper';
import Loading from 'components/loading/loading';
import { WithStrings, withStrings } from 'components/locale';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'components/search-results/filters/filters.styles';
import classNames from 'classnames';
import { FilterSection } from 'components/search-results/filters/filterSection';
import { SearchModel } from 'src/model/searchModel';
import { StudyCodesMap } from 'components/fetch/models/studyCodes';
import { LocaleStrings } from 'components/locale/strings';

interface Props extends WithStyles<typeof styles>, WithStrings<typeof getStrings> {
    searchModel: SearchModel;
    isMd?: boolean;
    containerClassName?: string;
    onSearchModelUpdated: (model: SearchModel) => void;
}

class Filter extends React.Component<Props> {
    render = () => {
        const { classes, isMd, containerClassName } = this.props;
        return (
            <div className={classNames(classes.filtersContainer, { [classes.mdContainer]: isMd }, containerClassName)}>
                <GetStudyCodes>
                    {({ data, error }) => {
                        if (data) {
                            return (
                                <>
                                    {this.renderHeader()}
                                    {this.renderFilters(data.Data)}
                                </>
                            );
                        }
                        if (error) {
                            return 'Error';
                        }
                        return <Loading color={{ fromTheme: 'background' }} />;
                    }}
                </GetStudyCodes>
            </div>
        );
    }

    private renderHeader = () => {
        const { strings, classes } = this.props;
        return (
            <div
                className={classNames(classes.row, classes.componentHeaderRow)}
            >
                <div>
                    <span className={classes.componentHeader}>
                        {strings.filtersHeader}
                    </span>
                </div>
                <div className={'text-right'}>
                    <button
                        className={classes.link}
                        onClick={this.clearAll}
                    >
                        {strings.common.clearAll}
                    </button>
                </div>
            </div>
        );
    };

    private renderFilters = (data: StudyCodesMap) => {
        const {
            classes,
            searchModel,
            strings,
            onSearchModelUpdated,
        } = this.props;
        const filterSections = mapFilters({ data, strings: strings.common });
        return (
            <>
            {filterSections.map(filter => (
            <FilterSection
                searchModel={searchModel}
                section={filter}
                classes={classes}
                onSearchModelUpdated={onSearchModelUpdated}
                key={filter.sectionKey}
            />
        ))}
        {[{parameterName: "Gender",
        sectionName: "Sex",
        sectionKey: "GENDER",
        items: [{
            internalValue: "GENDER_ALL",
            displayValue: "Both (Female and Male)",
            sortOrder: 0,
            items: []
            },
            {
            internalValue: "GENDER_MALE~GENDER_ALL",
            displayValue: "Male",
            sortOrder: 0,
            items: []
        },
        {
            internalValue: "GENDER_FEMALE~GENDER_ALL",
            displayValue: "Female",
            sortOrder: 0,
            items: []
        }]}].map(filter => (
        <FilterSection
            searchModel={searchModel}
            section={filter}
            classes={classes}
            onSearchModelUpdated={onSearchModelUpdated}
            key={filter.sectionKey}
        />
    ))}
        </>
        )
    };

    private clearAll = () => {
        const { searchModel, onSearchModelUpdated } = this.props;
        onSearchModelUpdated({ ...searchModel, filters: {}, conditions: [] });
    };
}

const getStrings = (s: LocaleStrings) => ({
    ...s.searchResults,
    common: s.common
});

export default withStyles(styles)(withStrings(Filter, getStrings));
