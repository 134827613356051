import { TenantModel } from 'src/model';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'components/layout/page.style';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import * as React from 'react';
import { TenantDataResolver } from 'src/services/tenantDataResolver';

interface Props extends WithStyles<typeof styles> {
    tenant: TenantModel;
    children: React.ReactNode;
    resolver: TenantDataResolver;
}

const Page = ({ classes, tenant, children, resolver }: Props) => {
    // TODO: header and footer should be rendered in https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
    return <div className={classes.wrap}>
        <Header
            tenantResolver={resolver}
            tenant={tenant}
        />
        {children}
        <Footer
            tenantResolver={resolver}
            tenant={tenant}
        />
    </div>;
};

export default withStyles(styles, { index: 1 })(Page);
