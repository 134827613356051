import * as React from 'react';
import {
    Modal as BootstrapModal,
    ModalBody,
    ModalHeader,
    ModalProps,
} from 'reactstrap';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'components/common/modal/modal.style';
import classNames from 'classnames';

interface Props extends ModalProps, WithStyles<typeof styles> {
    title?: string;
    isOpen: boolean;
    bodyClassName?: string;
    closeClassName?: string;
}

class Modal extends React.Component<Props> {
    render = () => {
        const { classes, children, className, bodyClassName } = this.props;
        const modalProps: ModalProps = {
            ...{ ...this.props, title: undefined, classes: undefined },
            className: classNames([classes.modal, className]),
            backdropClassName: classes.backdrop,
            contentClassName: classNames(classes.content, this.props.contentClassName),
            size: 'lg',
        };
        delete modalProps.bodyClassName;
        delete modalProps.closeClassName;
        return (
            <BootstrapModal {...modalProps}>
                <div>
                    {this.renderHeader()}
                    <ModalBody className={classNames([
                                    bodyClassName,
                                    classes.modalBody,
                                ])} children={children} />
                </div>
            </BootstrapModal>
        );
    };
    renderHeader = () => {
        const { classes, title, toggle } = this.props;
        return <ModalHeader
            toggle={toggle}
            close={this.renderCloseBtn()}
            className={classes.header}
        >
            {title || null}
        </ModalHeader>;
    };

    renderCloseBtn = () => <div
        role={'button'}
        className={classNames(this.props.classes.closeButton, this.props.closeClassName)}
        onClick={this.props.toggle}
    />;
}

export default withStyles(styles)(Modal);
