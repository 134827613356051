import React from 'react';
import { SearchModel } from 'src/model/searchModel';
import Filter from 'components/search-results/filters/filter';
import { parseFromQuery, serializeToQuery } from 'src/services/querySerializer';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from './container.styles';
import { Col, Row, Container } from 'reactstrap';
import ResultsList from 'components/search-results/resultsList/resultsList';
import SearchPanel from 'components/search-results/updateSearch/updateSearch';
import { navigate } from '@reach/router';
import FiltersModal from 'components/search-results/filtersModal/filtersModal';

interface Props extends WithStyles<typeof styles> {
    location: Location;
}

interface State {
    searchModel: SearchModel;
    isFiltersOpen: boolean;
}

class ResultsContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchModel: parseFromQuery(location.search),
            isFiltersOpen: false
        };
    }

    render = () => {
        const { classes } = this.props;
        const { searchModel, isFiltersOpen } = this.state;
        return (
            <div className={classes.resultsContainer}>
                <Row>
                    <Col>
                        <SearchPanel
                            searchModel={searchModel}
                            onSearchModelUpdated={this.onSearchModelUpdated}
                        />
                    </Col>
                </Row>
                <Container>
                    <Row className={classes.results}>
                        <Col lg={3}>
                            <Filter
                                searchModel={searchModel}
                                isMd={true}
                                onSearchModelUpdated={this.onSearchModelUpdated}
                            />
                        </Col>
                        <Col lg={9}>
                            <ResultsList
                                searchModel={searchModel}
                                onFilter={this.onFiltersToggle}
                            />
                        </Col>
                    </Row>
                </Container>
                <FiltersModal
                    searchModel={searchModel}
                    onSearchModelUpdated={this.onSearchModelUpdated}
                    isOpen={isFiltersOpen}
                    onToggle={this.onFiltersToggle} />
            </div>
        );
    };

    componentDidMount(): void {
        // TODO: this is workaround. This should be saved in global state
        const query = '?' + serializeToQuery(this.state.searchModel);
        window['trialFinderSearchQuery'] = query;
    }

    onSearchModelUpdated = (searchModel: SearchModel) => {
        this.setState({ searchModel: { ...searchModel } });
        // TODO: this is workaround. This should be saved in global state
        const query = '?' + serializeToQuery(searchModel);
        window['trialFinderSearchQuery'] = query;
        navigate(`/search-results/${query}`, {
            replace: true,
        });
    };

    private onFiltersToggle = () => this.setState({ isFiltersOpen: !this.state.isFiltersOpen });
}


export default withStyles(styles)(ResultsContainer);
