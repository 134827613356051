import { createStyles } from "@material-ui/styles";
import { Theme } from "components/common/theme/default";

export const styles = (theme: Theme) =>
         createStyles({
           container: {
             position: "relative",
             verticalAlign: "top",
             listStyle: "none",
             display: "block",
             width: "100%",
             paddingBottom: "5px",
             "&:hover": {
               "& label": {
                 textDecoration: "underline",
               },
               "& span": {
                 border: "2px solid #6D7278",
               },
             },
             "& input:focus": {
               "& ~ span": {
                 border: "2px solid #6D7278",
               },
             },
           },
           label: {
             color: theme.color.inputText,
             paddingLeft: "30px",
             lineHeight: "29px",
             userSelect: "none",
             cursor: "pointer",
             fontSize: "16px",
             fontWeight: 400,
             margin: "0",
           },
           checkbox: {
             position: "relative",
             userSelect: "none",
             display: "inline",
             cursor: "pointer",
             height: "21px",
             width: "21px",
             left: 0,
             verticalAlign: "top",
             "& span": {
               border: `1px solid ${theme.color.checkboxBorder}`,
               borderRadius: "5px",
               position: "absolute",
               height: "21px",
               width: "21px",
               left: 0,
               top: 0,
             },
             "& input": {
               position: "absolute",
               cursor: "pointer",
               height: "21px",
               width: "21px",
               opacity: 0,
               "&:checked ~ span": {
                 background: "url(/images/checked.svg) center center no-repeat",
                 backgroundSize: "14px 14px",
                 borderRadius: "5px",
                 opacity: 0.75,
               },
             },
           },
         });
