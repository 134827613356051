export const common = {
    stayConnected: 'Stay Connected',
    changeLanguage: 'Change language',
    selectAll: 'Select All',
    clearAll: 'Clear All',
    email: 'Email',
    phone: 'Phone',
    print: 'Print',
    country: 'Country',
    state: 'State',
    city: 'City',
    zip: 'Zip',
    status: 'Status',
    studyStatus: 'Study Status',
    notAvailable: 'Not Available',
    noResults: 'Sorry, the criteria specified did not match any Trials',
    askToJoin: 'Ask to Join',
    contactUs: 'Contact Us',
    readMore: 'Read More',
    requirements: 'Requirements',
    phase: 'Phase',
    age: 'Age',
    sex: 'Gender',
    distance: 'Distance',
    years: 'years',
    distanceValue: ['{distance} miles', ['distance']],
    yearsValue: ['{years} years', ['years']],
    keywordsSearchLabel: 'Keyword Search',
    locationLabel: 'Location',
    keywordsSearchPlaceHolder: 'Search by keyword',
    locationPlaceHolder: 'Search by location',
    distanceLabel: 'Distance',
    networkError: 'Sorry, we encountered a network error. Please try again.',    
    noContactsMessage: 'The sponsor hasn’t provided any contact information on ClinicalTrials.gov',
    noEmailContactMessage: 'The sponsor hasn’t provided email information on ClinicalTrials.gov',
    noPhoneContactMessage: 'The sponsor hasn’t provided phone information on ClinicalTrials.gov',
} as const;


