import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'src/components/search-results/updateSearch/updateSearch.styles';
import { withStrings, WithStrings } from 'components/locale';
import React, { createRef } from 'react';
import { Col, Container, Input, Row, Label } from 'reactstrap';
import Button from 'components/common/button/button';
import LocationInput, {
    Location,
} from 'components/common/locationInput/locationInput';
import { getFirstSugestion } from 'components/common/locationInput/getFirstSugestion';
import { SearchModel } from 'src/model/searchModel';
import SocialShare from 'components/search-results/socialShare/socialShare';

interface Props extends WithStyles<typeof styles>, WithStrings {
    searchModel: SearchModel;
    onSearchModelUpdated: (model: SearchModel) => void;
}

interface State {
    location: Location;
    keywords: string;
}

class UpdateSearch extends React.Component<Props, State> {
    formRef = createRef<HTMLFormElement>();

    constructor(props: Props) {
        super(props);
        const searchModel = props.searchModel;
        this.state = {
            location: {
                locationName: searchModel.locationName || '',
                lat: searchModel.lat || '',
                lng: searchModel.lng || '',
            },
            keywords: searchModel.keywords || '',
        };
    }

    render = () => {
        const { strings, classes } = this.props;
        const { location, keywords } = this.state;
        return (
            <form
                ref={this.formRef}
                className={classes.searchForm}
                onSubmit={this.onSubmit}
            >
                <Container>
                    <Row>
                        <Col md={6}>
                            <h1>{strings.searchResults.searchResults}</h1>
                        </Col>
                        <Col md={6}>
                            <SocialShare title={strings.searchResults.shareThisSearch}
                                         containerClassName={classes.socialShareContainer} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                                <Label for="keyword">{strings.common.keywordsSearchLabel}</Label>
                                <Input
                                    placeholder={
                                        strings.common.keywordsSearchPlaceHolder
                                    }
                                    onChange={this.onKeywordsChange}
                                    defaultValue={keywords}
                                    autoComplete={'off'}
                                    maxLength={150}
                                />
                        </Col>
                        <Col md={5}>
                            <Label for="location">{strings.homePage.locationLabel}</Label>
                            <LocationInput
                                placeholder={strings.common.locationPlaceHolder}
                                onLocationChanged={this.onLocationChanged}
                                value={location.locationName}
                            />
                        </Col>
                        <Col md={2} className={'text-center'}>
                            <Button>{strings.searchResults.updateSearch}</Button>
                            <a href="/search">{strings.homePage.advancedSearch}</a>
                            <SocialShare title={strings.searchResults.shareThisSearch}
                                         containerClassName={classes.socialShareContainerSm} />
                        </Col>
                    </Row>
                </Container>
            </form>
        );
    };

    private onKeywordsChange = (e: any) => {
        this.setState({
            keywords: e.target.value,
        });
    };

    private onLocationChanged = (location: Location) => {
        this.setState({
            location,
        });
    };

    private onSubmit = (e: any) => {
        e.preventDefault();
        const { keywords } = this.state;
        const searchModel = this.props.searchModel;
        let location:Location = {
            lat: '',
            lng: '',
            locationName: '',
        }
        if (this.state.location.locationName) {
            if(!this.state.location.lat && !this.state.location.lng){
                getFirstSugestion()
                .then((res: Location) => {
                    console.log("HERE", res);
                    location = res;
                    this.props.onSearchModelUpdated({
                        ...searchModel,
                        ...location,
                        keywords,
                    });
                })
                .catch((err)=>{
                    console.log("HERE_ERR", err);
                })
            }
            else {
                location = this.state.location;
                this.props.onSearchModelUpdated({
                    ...searchModel,
                    ...location,
                    keywords,
                });
            }
        } else {
            this.props.onSearchModelUpdated({
                ...searchModel,
                ...location,
                keywords,
            });
        }
    };
}

export default withStyles(styles)(withStrings(UpdateSearch));
