export const createDataAttributes = (
    dataAttribute: string,
    values: string[] | string
) => {
    if (!Array.isArray(values)) {
        return createSelector(
            dataAttribute,
            values
        );
    }
    return values
        .map(val =>
            createSelector(
                dataAttribute,
                val
            )
        )
        .join(', ');
};

const createSelector = (dataAttribute: string, val: string) =>
    `[data-${dataAttribute}="${val}"]`;
