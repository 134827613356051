export const searchResults = {
    filtersHeader: 'Filter',
    shareThisSearch: 'Share this search',
    updateSearch: 'Search',
    searchResults: 'Search Results',
    learnMore: 'Learn More',
    results: ['<b>{count}</b> of <b>{total}</b> Results', ['count', 'total'], 'html'],
    condition: 'Condition',
    loadMore: 'Load More',
    medicalConditionsHeader: 'Medical Conditions',
} as const;
