import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from './filtersModal.styles';
import { SearchModel } from 'src/model/searchModel';
import Filter from 'components/search-results/filters/filter';
import * as React from 'react';
import Modal from 'components/common/modal/modal';
import { defaultTheme } from 'components/common/theme/default';

interface Props extends WithStyles<typeof styles> {
    isOpen: boolean;
    searchModel: SearchModel;
    onSearchModelUpdated: (model: SearchModel) => void;
    onToggle: () => void;
}

interface State {
    isSm: boolean;
}

class FiltersModal extends React.Component<Props, State> {

    state: State = {
        isSm: false
    };

    render = () => {
        const { classes, isOpen, onToggle, onSearchModelUpdated, searchModel } = this.props;
        const { isSm } = this.state;
        const shouldBeOpened = isSm && isOpen;
        return <Modal isOpen={shouldBeOpened} toggle={onToggle} className={classes.modalContainer}
                      contentClassName={classes.modalContent}
                      bodyClassName={classes.modalBody}
                      closeClassName={classes.close}
                      modalClassName={classes.modalDialog}
        >
            <Filter searchModel={searchModel}
                    containerClassName={classes.filtersContainer}
                    onSearchModelUpdated={onSearchModelUpdated} />
        </Modal>;
    };

    componentDidMount(): void {
        this.updateSize();
        window.addEventListener('resize', this.updateSize);
    }
    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateSize);
    }

    updateSize = () => {
        const isSm = window.innerWidth <= defaultTheme.breakpoints.values.lg;
        if (isSm !== this.state.isSm) {
            this.setState({ isSm });
        }
    }
}

export default withStyles(styles)(FiltersModal);
